import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogContent,
  Drawer,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Radio,
  RadioGroup,
  Step,
  StepLabel,
  Stepper,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
  withStyles,
} from "@material-ui/core";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { newlogo, women, professionalDetailsImg } from "./assets";
import { Check, Close } from "@material-ui/icons";
import TermsConditions from "../../termsconditions/src/TermsConditions.web";
const navigation = require("react-navigation");
import { Alert } from "@material-ui/lab";


const configJSON = require("./config");

const theme = createTheme({
  palette: {
    primary: {
      main: "#14362E",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

import ProfessionalDetailsController, {
  Props,
} from "./ProfessionalDetailsController";

class ProfessionalDetails extends ProfessionalDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  professionalDetailsWeb() {
    const { dummyRadioOptions } = this.state;
    return (

      <Grid container style={{ height: "0px" }} spacing={5}>
        <Grid item md={9} style={{ paddingInline: "80px" }}>
          <Grid container spacing={4}>
            <Grid
              item
              xs={12}
              style={{
                marginTop: "50px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={newlogo}
                style={{ width: "249.88px", height: "45.59px" }}
              />
              <Box sx={{ width: "100%", textAlign: "center", marginTop: "25px" }}>
                <img src={professionalDetailsImg} alt="Professional Details" width={201} height={25} />
              </Box>
              <Typography
                style={{
                  fontSize: "20px",
                  color: "#14362E",
                  fontFamily: "MyFont",
                  letterSpacing: "1.3",
                  fontWeight: 800,
                }}
              >
                {configJSON.professionalDetails}
              </Typography>
              {
                this.state.errorCheckBox && (
                  <Alert severity="error">
                    To sign up, please agree with Privacy Policy, GDPR and Terms & Conditions.
                  </Alert>
                )
              }

            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={6}>
                  <InputLabel
                    style={{
                      fontSize: "16px",
                      fontWeight: 700,
                      color: "#334155",
                      fontFamily: "MyFont",
                      letterSpacing: "1.4",
                    }}
                  >
                    {configJSON.profDetailsHeading1}
                  </InputLabel>
                  <TextField
                    fullWidth
                    value={this.state.professionalLevel}
                    placeholder="Select an option"
                    data-test-id="prof-level-web"
                    variant="outlined"
                    onClick={this.toggleDrawer("web", true, `${configJSON.profDetailsHeading1}`, 1)}
                    InputProps={{
                      style: { marginTop: "5px", borderRadius: "8px" },
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={this.toggleDrawer("web", true, `${configJSON.profDetailsHeading1}`, 1)}>
                            <ArrowDropDownIcon />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                  <InputLabel
                    style={{
                      fontSize: "16px",
                      fontWeight: 700,
                      color: "#334155",
                      fontFamily: "MyFont",
                      letterSpacing: "1.4",
                    }}
                  >
                    {configJSON.profDetailsHeading2}
                  </InputLabel>
                  <TextField
                    value={this.state.employeesReporting}
                    fullWidth
                    placeholder="Select an option"
                    data-test-id="emp-prof-level-web"
                    variant="outlined"
                    onClick={this.toggleDrawer("web", true, `${configJSON.profDetailsHeading2}`, 2)}
                    InputProps={{
                      style: { marginTop: "5px", borderRadius: "8px" },
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={this.toggleDrawer("web", true, `${configJSON.profDetailsHeading2}`, 2)}>
                            <ArrowDropDownIcon />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={6}>
                  <InputLabel
                    style={{
                      fontSize: "16px",
                      fontWeight: 700,
                      color: "#334155",
                      fontFamily: "MyFont",
                      letterSpacing: "1.4",
                    }}
                  >
                    {configJSON.profDetailsHeading3}

                  </InputLabel>
                  <TextField
                    fullWidth
                    value={this.state.organizationSize}
                    data-test-id="org-size-web"
                    placeholder="Select an option"
                    variant="outlined"
                    onClick={this.toggleDrawer("web", true, `${configJSON.profDetailsHeading3}`, 3)}
                    InputProps={{
                      style: { marginTop: "5px", borderRadius: "8px" },
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={this.toggleDrawer("web", true, `${configJSON.profDetailsHeading3}`, 3)}>
                            <ArrowDropDownIcon />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <InputLabel
                    style={{
                      fontSize: "16px",
                      fontWeight: 700,
                      color: "#334155",
                      fontFamily: "MyFont",
                      letterSpacing: "1.4",
                    }}
                  >
                    {configJSON.profDetailsHeading4}
                  </InputLabel>
                  <TextField
                    fullWidth
                    value={this.state.heardAboutKellaFrom}
                    placeholder="Select an option"
                    data-test-id="hear-about-web"
                    variant="outlined"
                    onClick={this.toggleDrawer("web", true, `${configJSON.profDetailsHeading4}`, 4)}
                    InputProps={{
                      style: { marginTop: "5px", borderRadius: "8px" },
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={this.toggleDrawer("web", true, `${configJSON.profDetailsHeading4}`, 4)}>
                            <ArrowDropDownIcon />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}

                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={6}>
                  <InputLabel
                    style={{
                      fontSize: "16px",
                      fontWeight: 700,
                      color: "#334155",
                      fontFamily: "MyFont",
                      letterSpacing: "1.4",
                    }}
                  >
                    Are you supported by your company?
                  </InputLabel>
                  <Typography
                    style={{
                      color: "#64748B",
                      fontSize: "11px",
                      fontWeight: 500,
                      lineHeight: "18px",
                      fontFamily: "MyFont",
                      margin: "1% 0px",
                    }}
                  >
                    If so, please refer the financial/HR department email.
                  </Typography>
                  <TextField
                    fullWidth
                    type="email"
                    variant="outlined"
                    placeholder="Enter email"
                    InputProps={{
                      style: {
                        marginTop: "5px",
                        borderRadius: "8px",
                        borderColor: "#CBD5E1",
                      },
                    }}
                    onChange={this.handleChange}
                    data-test-id='emailChange'
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Dialog id="model_add" PaperProps={{ style: { borderRadius: '0 24px 0 0', } }} className="professional-dailog" maxWidth="sm"
                data-test-id="dialogClose"
                open={this.state.showModel} onClose={this.handleDialogClose} >
                <Box style={webStyle.termsWrapper} >
                  <Close
                    className="professional-close"
                    data-test-id="handleDialogCloseWeb"
                    onClick={this.handleDialogClose}
                  /></Box>
                <DialogContent style={{ padding: 0 }}>
                  <TermsConditions handleDialogClose={this.handleDialogClose} id={"TermsConditions"} navigation={navigation} />
                </DialogContent>
              </Dialog>
            </Grid>

            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "center", gap: "10px" }}
            >
              <Box style={{ width: "234px", height: "56px" }}>
                <Button
                  variant="outlined"
                  fullWidth
                  style={{
                    border: "1px solid #14362E",
                    borderRadius: "8px",
                    height: "45px",
                    color: "#14362E",
                    fontWeight: 700,
                    fontFamily: "MyFont",
                    letterSpacing: "1px",
                    fontSize: "16px",
                    textTransform: "capitalize",
                  }}
                  href="/AccountDetails"
                >
                  Back
                </Button>
              </Box>
              <Box style={{ width: "234px", height: "56px" }}>
                <Button
                  variant="contained"
                  fullWidth
                  style={{
                    backgroundColor: "#14362E",
                    borderRadius: "8px",
                    height: "45px",
                    color: "white",
                    fontWeight: 700,
                    fontFamily: "MyFont",
                    letterSpacing: "1px",
                    fontSize: "16px",
                    textTransform: "capitalize",
                  }}
                  onClick={this.updateProfessionalDetails}
                  data-test-id="btnProfessionalDetails"
                >
                  Submit
                </Button>
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Box>
                <Typography
                  style={{
                    fontWeight: 400,
                    fontSize: "16px",
                    fontFamily: "MyFont",
                    letterSpacing: "1px",
                  }}
                >
                  Already have an account?{" "}
                  <a
                    href="/EmailAccountLoginBlock"
                    style={{
                      fontSize: "16px",
                      fontWeight: 700,
                      color: "#14362E",
                      textDecoration: "none",
                    }}
                  >
                    Log in
                  </a>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={3}>
          <img
            src={women}
            alt="Women"
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              objectFit: "contain",
              position: "fixed",
              right: 0,
            }}
          />
        </Grid>

        <Drawer anchor="right" open={this.state.webDrawerOpen} onClose={this.toggleDrawer("web", false, '', this.state.dummyState)}
          PaperProps={{
            style: {
              borderTopLeftRadius: '20px',
              width: '484px'
            },
          }}
        >
          <Box style={{ display: 'flex', justifyContent: "space-between", alignItems: "center", width: "100%", borderBottom: "1.04px solid #CBD5E1" }}>
            <Box style={{ display: "flex", padding: '4% 0 0 4%', width: '350px' }}>
              <Typography style={{ fontSize: "24px", fontWeight: 600, color: "#14362E", fontFamily: "MyFont", letterSpacing: "1.5px" }}> {this.state.drawerHeading}</Typography>
            </Box>
            <Box>
              <IconButton
                style={{ right: '10px', margin: '15px 5px' }}
                onClick={this.toggleDrawer("web", false, '', this.state.dummyState)}
              >
                <Close />
              </IconButton>
            </Box>
          </Box>
          <Box style={{ overflow: 'auto', marginTop: "30px" }}>
            <FormControl style={{ display: 'flex', justifyContent: 'space-between' }}>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={this.state.selectedOption}
                onChange={this.handleOptionChange}
                data-test-id='webRadioBtn'
              >
                {dummyRadioOptions.map((option: any, index: number) => (
                  <Box key={index} sx={{ margin: '0 25px', borderBottom: '1px solid #CBD5E1' }}>
                    <FormControlLabel
                      label={option.label}
                      value={option.value}
                      control={<GreenRadio />}
                      labelPlacement="start"
                      style={webStyle.formLabel}
                    />
                  </Box>
                ))}
              </RadioGroup>
            </FormControl>
          </Box>
          <Box style={{ width: "428px", height: "56px", position: 'absolute', bottom: 0, padding: '0 30px' }}>
            <Button
              variant="contained"
              fullWidth
              style={{
                backgroundColor: "#14362E",
                borderRadius: "8px",
                height: "45px",
                color: "white",
                fontWeight: 700,
                fontFamily: "MyFont",
                letterSpacing: "1px",
                fontSize: "16px",
                textTransform: "capitalize",
              }}
              data-test-id={"selectoptionId"}
              onClick={this.handleSelect}
            >
              {configJSON.select}
            </Button>
          </Box>
        </Drawer>
      </Grid>
    );
  }

  professionalDetailsMobile() {
    const { dummyRadioOptions } = this.state;
    return (
      <Container style={{ display: "flex", justifyContent: "center" }}>
        <Grid item container xs={12} style={{ height: "0px" }} spacing={4}>
          <Grid item xs={12}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              marginTop: "50px",
              alignItems: "center",
            }}>
            <img
              src={newlogo}
              style={{ height: "45.59px", width: "249.88px" }}
            />
            <Box sx={{ width: "100%", textAlign: "center", marginTop: "25px" }}>
              <img src={professionalDetailsImg} alt="Professional Details"  width={201} height={25} />
            </Box>

            <Typography style={{ fontSize: "20px", fontWeight: 800, color: "#14362E" }}>
              {configJSON.professionalDetails}
            </Typography>
            {
              this.state.errorCheckBox && (
                <Alert severity="error">
                  To sign up, please agree with Privacy Policy, GDPR and Terms & Conditions.
                </Alert>
              )
            }
          </Grid>
          <Grid container spacing={4} justifyContent="center" style={{ width: '100%', margin: '0' }}>
            <Grid item xs={12} md={6}>
              <InputLabel style={{ fontSize: "16px", fontWeight: 700, color: "#334155" }}> {configJSON.profDetailsHeading1}</InputLabel>
              <TextField
                fullWidth
                value={this.state.professionalLevel}
                placeholder="Select an option"
                data-test-id="prof-level-mobile"
                variant="outlined"
                onClick={this.toggleDrawer("mobile", true, `${configJSON.profDetailsHeading1}`, 1)}
                InputProps={{
                  style: { marginTop: "5px", borderRadius: "8px" },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={this.toggleDrawer("mobile", true, `${configJSON.profDetailsHeading1}`, 1)}>
                        <ArrowDropDownIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel style={{ fontSize: "16px", fontWeight: 700, color: "#334155" }}>{configJSON.profDetailsHeading2}</InputLabel>
              <TextField
                fullWidth
                value={this.state.employeesReporting}
                placeholder="Select an option"
                data-test-id="emp-prof-level-mobile"
                variant="outlined"
                onClick={this.toggleDrawer("mobile", true, `${configJSON.profDetailsHeading2}`, 2)}
                InputProps={{
                  style: { marginTop: "5px", borderRadius: "8px" },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={this.toggleDrawer("mobile", true, `${configJSON.profDetailsHeading2}`, 2)}>
                        <ArrowDropDownIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <InputLabel style={{ fontSize: "16px", fontWeight: 700, color: "#334155" }}>{configJSON.profDetailsHeading3}</InputLabel>
              <TextField
                fullWidth
                value={this.state.organizationSize}
                data-test-id="org-size-mobile"
                placeholder="Select an option"
                variant="outlined"
                onClick={this.toggleDrawer("mobile", true, `${configJSON.profDetailsHeading3}`, 3)}
                InputProps={{
                  style: { marginTop: "5px", borderRadius: "8px" },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={this.toggleDrawer("mobile", true, `${configJSON.profDetailsHeading3}`, 3)}>
                        <ArrowDropDownIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <InputLabel style={{ fontSize: "16px", fontWeight: 700, color: "#334155" }}>Are you supported by your company?</InputLabel>
              <Typography
                style={{
                  color: "#64748B",
                  fontSize: "11px",
                  fontWeight: 500,
                  lineHeight: "18px",
                  fontFamily: "MyFont",
                  margin: "1% 0px",
                }}
              >
                If so, please refer the financial/HR department email.
              </Typography>
              <TextField
                fullWidth
                type="text"
                variant="outlined"
                placeholder="Enter email"
                InputProps={{
                  style: {
                    marginTop: "5px",
                    borderRadius: "8px",
                    borderColor: "#CBD5E1",
                  },
                }}
                onChange={this.handleChange}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <InputLabel style={{ fontSize: "16px", fontWeight: 700, color: "#334155" }}>{configJSON.profDetailsHeading4}</InputLabel>
              <TextField
                variant="outlined"
                value={this.state.heardAboutKellaFrom}
                placeholder="Select an option"
                data-test-id="hear-about-mobile"
                fullWidth
                onClick={this.toggleDrawer("mobile", true, `${configJSON.profDetailsHeading4}`, 4)}
                InputProps={{
                  style: { marginTop: "5px", borderRadius: "8px" },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={this.toggleDrawer("mobile", true, `${configJSON.profDetailsHeading4}`, 4)}>
                        <ArrowDropDownIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Dialog id="model_add" PaperProps={{ style: { borderRadius: '0 24px 0 0' } }} maxWidth="sm"
                open={this.state.showModel} onClose={this.handleDialogClose} >
                <Box style={webStyle.termsWrapper} >
                  <Close
                    data-test-id="handleDialogCloseWeb"
                    className="dailog-close-pd"
                    onClick={this.handleDialogClose}
                  /></Box>
                <DialogContent style={{ padding: 0 }} className="dailog-pd">
                  <TermsConditions handleDialogClose={this.handleDialogClose} id={"TermsConditions"} navigation={navigation} />
                </DialogContent>
              </Dialog>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ gap: "10px", display: "flex", justifyContent: "center" }}
            >
              <Box style={{ width: "234px", height: "56px" }}>
                <Button
                  variant="outlined"
                  fullWidth
                  style={{
                    height: "45px",
                    fontWeight: 700,
                    border: "1px solid #14362E",
                    borderRadius: "8px",
                    textTransform: "capitalize",
                    fontSize: "16px",
                    color: "#14362E",
                    fontFamily: "MyFont",
                    letterSpacing: "1px",
                  }}
                  href="/AccountDetails"
                >
                  Back
                </Button>
              </Box>
              <Box style={{ height: "56px", width: "234px" }}>
                <Button
                  variant="contained"
                  style={{
                    height: "45px",
                    color: "white",
                    textTransform: "capitalize",
                    fontFamily: "MyFont",
                    letterSpacing: "1px",
                    borderRadius: "8px",
                    fontWeight: 700,
                    fontSize: "16px",
                    backgroundColor: "#14362E",
                  }}
                  fullWidth
                  onClick={this.updateProfessionalDetails}
                >
                  Submit
                </Button>
              </Box>
            </Grid>

            <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
              <Box>
                <Typography style={{ fontWeight: 400, fontSize: "16px", fontFamily: "MyFont", letterSpacing: "1px" }}>Already have an account? <a href="/EmailAccountLoginBlock" style={{ fontSize: "16px", color: "#14362E", textDecoration: "none", fontWeight: 700 }}>Log in</a></Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Drawer anchor="bottom" open={this.state.mobileDrawerOpen} onClose={this.toggleDrawer("mobile", false, '', this.state.dummyState)}
          PaperProps={{
            style: {
              borderTopRightRadius: '20px',
              height: "75%",
              overflowY: 'unset',
            },
          }}

        >
          <Box style={{ display: 'flex', alignItems: "center", justifyContent: "flex-end", width: "100%" }}>
            <IconButton
              onClick={this.toggleDrawer("mobile", false, '', this.state.dummyState)}
              style={{ right: '10px' }}
            >
              <Close />
            </IconButton>
          </Box>
          <Box style={{ padding: "10px 15px" }}>
            <Typography style={{ fontSize: "24px", fontWeight: 600, color: "#14362E" }}>{this.state.drawerHeading}</Typography>
          </Box>
          <Box style={{ display: "flex" }}>
            <FormControl style={{ display: 'flex', width: '100%', justifyContent: 'space-between', flexWrap: 'nowrap', overflowY: 'auto', height: '40vh' }}>
              <RadioGroup
                value={this.state.selectedOption}
                aria-labelledby="demo-controlled-radio-buttons-group"
                onChange={this.handleOptionChange}
                name="controlled-radio-buttons-group"
                data-test-id='webRadioBtn'
              >
                {dummyRadioOptions.map((option: any, index: number) => (
                  <Box key={index} sx={webStyle.formLabelBox}>
                    <FormControlLabel
                      value={option.value}
                      style={webStyle.formLabel}
                      control={<GreenRadio />}
                      labelPlacement="start"
                      label={option.label}
                    />
                  </Box>
                ))}
              </RadioGroup>
            </FormControl>
          </Box>
          <Box style={{
            height: "56px",
            padding: '0 20px',
            margin: '10px auto',
            maxWidth: ' 350px',
            width: '100%',
            boxSizing: 'border-box',
            marginTop: 'auto'
          }}>
            <Button
              variant="contained"
              fullWidth
              style={{
                height: "45px",
                fontSize: "16px",
                backgroundColor: "#14362E",
                borderRadius: "8px",
                fontWeight: 700,
                fontFamily: "MyFont",
                color: "white",
                textTransform: "capitalize",
                letterSpacing: "1px",
              }}
              onClick={this.handleSelect}
              data-test-id='select-btn'
            >
              {configJSON.select}
            </Button>
          </Box>
        </Drawer>

      </Container >
    )
  }

  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={false} style={{ display: "flex" }}>
          <Box
            display={{ xs: "none", sm: "none", md: "flex" }}
            style={{ width: "100%" }}
          >
            {this.professionalDetailsWeb()}
          </Box>
        </Container>
        <Box display={{ xs: "flex", sm: "flex", md: "none" }}>
          {this.professionalDetailsMobile()}
        </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

export default ProfessionalDetails;
const webStyle = {
  CustomCheckbox: {
    borderRadius: "8px",
  },
  selectBox: {
    marginTop: "5px",
    borderRadius: "8px",
    borderColor: "#CBD5E1",
  },
  stepperSteps: {
    background: "#14362E",
    borderRadius: "200px",
    width: "24px",
    height: "24px",
    gap: "10px",
    color: "white",
    padding: "3px",
  },
  "custom-menu-paper": {
    "& .MuiMenuItem-root": {
      padding: "10px",
    },
    "& .Mui-selected": {
      backgroundColor: "blue",
      color: "white",
    },
  },
  termsWrapper: {
    display: "flex",
    justifyContent: "right",
    padding: "16px 12px",
  },
  formLabel: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: "8px 0",
    color: '#475569',
    fontWeight: 400,
    fontSize: '14px',
    fontFamily: "MyFont",
    lineHeight: '22px',
  },
  formLabelBox: {
    margin: '0 25px', borderBottom: '1px solid #CBD5E1'
  },
  boldFont: { fontWeight: 700, color: "#14362E", cursor: 'pointer' }
};
const GreenRadio = withStyles({
  root: {
    color: '#73A58E',
    '&$checked': {
      color: '#73A58E'
    }
  },
  checked: {}
})((props) => <Radio color="default" {...props} />)
// Customizable Area End
