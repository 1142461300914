import React from "react";

// Customizable Area Start
import { Box, Typography, styled, Button, Drawer } from "@material-ui/core";
import { ArrowBack, CheckCircle, Close } from "@material-ui/icons";
import { greenCheck } from "./assets";
// Customizable Area End

import PaymentController, { Props } from "./PaymentController.web";
import SubscriptionCard from "../../../components/src/SubscriptionCard.web";
import moment from "moment";
import ResponsiveDrawer from "../../../components/src/ResponsiveDrawer.web";

class Payment extends PaymentController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  subscriptionDetails (){
    return(
  
        <ResponsiveDrawer title={'Plan details'} handleClose={this.handleCloseSubscriptionDetails} open={this.state.subscriptionDetailsOpen}>
            <SubscriptionCard
              name={this.state.currentPlanData?.plan.name}
              features={this.state.currentPlanData?.plan.features}
              amount={this.state.currentPlanData?.plan.amount}
              status={this.state.currentPlanData?.status}
              title={this.state.currentPlanData?.plan.title}
              interval={this.state.currentPlanData?.plan.interval}
            />
        </ResponsiveDrawer>
    )
  }
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <Setting>
        <Box className="outerBox">
          <Box className="arrow">
              <ArrowBack onClick={this.goBack} />
          </Box>
          <Box className="heading">Payment</Box> 
          <Box className="mainBox">
            <Box className="innerBox">
              <Box className="headBox">
                <Typography className="headingMembership">
                  Membership plan
                </Typography>
                <Typography className="headingEdit" onClick={this.handleEdit} style={{cursor:'pointer'}}>Edit</Typography>
              </Box>
              <SubscriptionCard
                name={this.state.currentPlanData?.plan.name}
                features={this.state.currentPlanData?.plan.features}
                handleViewDetails={this.handleOpenSubscriptionDetails}
                amount={this.state.currentPlanData?.plan.amount}
                status={this.state.currentPlanData?.status}
                interval={this.state.currentPlanData?.plan.interval}
              />
              <Box className="subs-billing" sx={{mt: '20px'}}>
                <Box className="headBox">
                  <Typography className="subscriptionBilling">
                    Subscription billing
                  </Typography>
                  <Typography className="viewDetails" onClick={this.goToBilling}>View all</Typography>
                </Box>
                <Box className="subscriptionBillingBox">
                  <Box className="subscriptionsBillingInnerBox">
                    <Box className="headBox">
                      <Typography className="subs-billing-left-text">
                        Payment date
                      </Typography>
                      <Typography className="subs-billing-right-text">
                        {this.state.currentPlanData?.billing && moment(this.state.currentPlanData.billing.start_date).format('MMM D, YYYY')}
                      </Typography>
                    </Box>
                    <Box className="headBox">
                      <Typography className="subs-billing-left-text">
                        Plan End date
                      </Typography>
                      <Typography className="subs-billing-right-text">
                      {this.state.currentPlanData?.billing && moment(this.state.currentPlanData.billing.end_date).format('MMM D, YYYY')}
                      </Typography>
                    </Box>
                    {
                      this.state.currentPlanData?.billing.promo_code && <>
                        <Box className="headBox">
                          <Typography className="subs-billing-left-text">
                            Promo code
                          </Typography>
                          <Typography className="subs-billing-right-text">
                            {this.state.currentPlanData.billing.promo_code}
                          </Typography>
                        </Box>
                        <Box className="headBox">
                          <Typography className="subs-billing-left-text">
                            Promo discount
                          </Typography>
                          <Typography className="subs-billing-right-text">
                            {this.state.currentPlanData?.billing.discount}
                          </Typography>
                        </Box>
                      </>
                    }

                    <Box className="headBox">
                      <Typography className="subs-billing-left-text">
                        Total amount
                      </Typography>
                      <Typography className="subs-billing-right-text-olive">
                        €{this.state.currentPlanData?.billing.amount_paid}<span color="#73A58E">+ VAT</span>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        {this.subscriptionDetails()}
      </Setting>
      // Customizable Area End
    );
  }
}
// Customizable Area Start

export default Payment;

const Setting = styled(Box)({
  "& .outerBox": {
    backgroundColor: "#f2fff2",
    width: "100%",
    height: "100%",  
  },
  "& .arrow": {
    height: "48px",
    padding: "0px 2%",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#FFFFFF",  
  },
  "& .heading": {
    fontFamily: "MyFont",
    backgroundColor: "#FFFFFF",
    display: "flex",
    alignItems: "center",
    paddingLeft: "6%",
    height: "56px",
    fontSize: "24px",
    fontWeight: 700,   
  },
  "& .mainBox": {
    maxWidth: "816px",
    margin: "2% auto",
    borderRadius: "16px",
    backgroundColor: "#FFFFFF", 
    padding: "24px 20px",
    boxSizing: 'border-box'
  },
  "& .innerBox": {
    margin: "auto",
    maxWidth: "450px",
    display: "flex",
    flexDirection: "column",  
  },
  "& .headBox": {
    display: "flex",
    justifyContent: "space-between",
  },
  "& .promo": {
    display: "flex",
    alignItems: "center",
    marginTop: "2%",
    height: "64px",
  },
  "& .membershipBox": {
    borderRadius: "8px 8px 32px 8px",
    border: "2px solid #73A58E",
    padding: "16px",
    margin: "2% 0",
  },
  "& .headingMembership": {
    color: " #334155",
    fontSize: "16px",
    fontWeight: 900,
    letterSpacing: "0.05em",
    fontFamily: "'MyFont', 'Halyard Display'",
    marginBottom:'20px'
  },
  "& .headingEdit": {
    color: "#14362E",
    fontSize: "16px",
    fontWeight: 900,
    letterSpacing: "0.05em",
    fontFamily: "'MyFont', 'Halyard Display'",
  },
  "& .kellaEmpower": {
    color: "#0F172A",
    fontSize: "16px",
    fontWeight: "bolder",
    letterSpacing: "0.05em",
    fontFamily: "'MyFont', 'Halyard Display'",
  },
  "& .activeButton": {
    textTransform: "none",
    backgroundColor: "#14362E",
    color: "#FFFFFF",
    borderRadius: "20px",
    height: "20px",
    fontWeight: "bolder",
    letterSpacing: "0.05em",
    fontFamily: "'MyFont', 'Halyard Display'",
  },
  "& .featureBox": {
    display: "flex",
    marginTop: "8px",
    marginBottom: "8px",
  },
  "& .kellaTexts": {
    color: "#14362E",
    marginLeft: "20px",
    fontSize: "14px",
    letterSpacing: "0.05em",
    fontFamily: "'MyFont', 'Halyard Display'",
  },
  "& .kellaGrayText": {
    color: "#64748B",
    fontSize: "12px",
    letterSpacing: "0.05em",
    marginLeft: "25px",
    fontFamily: "'MyFont', 'Halyard Display'",
  },
  "& .kella400": {
    color: "#14362E",
    fontSize: "16px",
    fontWeight: "bolder",
    letterSpacing: "0.05em",
    fontFamily: "'MyFont', 'Halyard Display'",
  },
  "& .promoCode": {
    color: "#0F172A",
    fontSize: "16px",
    letterSpacing: "0.08em",
    fontFamily: "'MyFont', 'Halyard Display'",
    marginLeft: "5px",
  },
  "& .viewDetails": {
    color: "#14362E",
    fontSize: "16px",
    fontWeight: "bolder",
    letterSpacing: "0.08em",
    fontFamily: "'MyFont', 'Halyard Display'",
  },
  "& .subscriptionBilling": {
    color: "#334155",
    fontWeight: "bolder",
    letterSpacing: "0.08em",
    fontFamily: "'MyFont', 'Halyard Display'",
  },
  "& .subscriptionBillingBox": {
    marginTop: "10px",
    backgroundColor: "#f2fff2",
    borderRadius: "10px",
    height: "160px",
  },
  "& .subscriptionsBillingInnerBox": {
    padding: "15px",
  },
  "& .subs-billing-left-text": {
    color: "#000000",
    fontSize: "14px",
    letterSpacing: "0.08em",
    fontFamily: "'MyFont', 'Halyard Display'",
    marginTop: "6px",
  },
  "& .subs-billing-right-text": {
    color: "#14362E",
    fontSize: "14px",
    letterSpacing: "0.08em",
    fontWeight: "bolder",
    fontFamily: "'MyFont', 'Halyard Display'",
    marginTop: "6px",
  },
  "& .subs-billing-right-text-olive": {
    color: "#73A58E",
    fontSize: "14px",
    letterSpacing: "0.08em",
    fontWeight: "bolder",
    fontFamily: "'MyFont', 'Halyard Display'",
    marginTop: "6px",
  },
});

const CustomDrawer = styled(Drawer)({
  '@media (max-width: 600px)': {
    '& .MuiDrawer-paper': {
      bottom: '0',
      top: 'unset',
      height: '80vh',
      paddingBottom: '24px'
    },
},
'@media (max-height: 585px)': {
  '& .scrollableCard':{
    height: "468px",
    overflowY: 'auto'
  },
},
  '& .MuiDrawer-paper': {
    maxWidth: '484px',
    width:'100%',
    borderRadius: "24px 0px 0px 0px",
  },
  '& .TextStyle': {
    fontSize:"24px",
    fontFamily: "MyFont !important",
    fontWeight:700,
    lineHeight:"32px",
  },
  '& .TextStyle2': {
    fontSize:"20px",
    fontFamily: "MyFont !important",
    fontWeight:500,
    lineHeight:"28px",
  },
  '& .boxTextStyle': {
    height: "18px",
    paddingTop: "3%",
    fontSize:"12px",
    fontFamily: "MyFont !important",
    fontWeight:400,
    lineHeight:"18px",
    color: "#73A58E"
  },
  '& .Typostyle':{
    fontFamily:"MyFont !imporatnt",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    color: "#334155",
  },
  '& .boxTypoStyle1':{
   
    fontFamily:"MyFont !important",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "8px",
  },
  '& .boxTypoStyle2':{
    fontFamily:"MyFont !important",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "8px",
  },
  '& .buttonStyle':{
    height: "56px",
    margin:'15px',
    boderRadius:"8px",
    backgroundColor: "#14362E",
    color:"#FFFFFF"
  },
  '& .buttonStyle2':{
    height: "56px",
    margin:'15px',
    width: "350px",
    boderRadius:"8px",
    backgroundColor: "#14362E",
    color:"#FFFFFF",
    top:"79%",
  },
  '& .mainViewDetailDrawerStyle':{
    height: "1024px",
    left:"956px",
    borderRadiusadius:"24px, 0px, 0px, 0px",
    Padding:"0px, 0px, 40px, 0px",
    Gap:"24px",
    boxShadow: "0px 8.31px 33.24px 0px #0000000F",
  },
  
  '& .viewdrawerStyle':{
    height: "73px",
    padding: "12px 28px 12px 28px !important"

  },
  '& .viewdrawerBoxStyle':{
    height: "468px",
    marginTop:"25px",
    gap: "6px !important",
    border : "1px solid #73A58E",
    borderRadius: '8px 8px 32px 8px'
  },
  '& .viewdrawerBoxStyle2':{
    height: "480px",
    marginTop:"25px",
    gap: "6px !important",
    border : "1px solid #73A58E",
    borderRadius: '8px 8px 32px 8px'
  },
  '& .viewDrawerTypoStyle':{
    fontFamily:"MyFont !important",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
  },
  '& .viewDrawerButton':{
    height: "56px",
    margin:'15px',
    boderRadius:"8px",
    backgroundColor: "#14362E",
    color:"#FFFFFF",
    top:"79%",
  },
  '& .viewDrawerButton2':{
    height: "56px",
    margin:'15px',
    boderRadius:"8px",
    backgroundColor: "#14362E",
    color:"#FFFFFF",
    top:"79%",
    right:"20px"
  },
})
const webStyle = {
  boxTextFormat:{
    fontSize:"12px",
    fontFamily: "MyFont ",
    fontWeight:700,
    letterSpacing:"8%",
    lineHeight:"18px",
  },
}
// Customizable Area End
