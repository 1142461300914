Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "settings2";
exports.labelBodyText = "settings2 Body";

exports.btnExampleTitle = "CLICK ME";

exports.privacyPolicy = "Privacy Policy";
exports.securityPolicy = "Security Policy";
exports.membershipAgreement = "Membership Agreement";
exports.communityStandards = "Community Standards";
exports.consentManagement = "Consent Management";
exports.complaintsHandlingPolicy = "Complaints Handling Policy";
exports.nonSolicitationPolicy = "Non Solicitation Policy";
exports.termsAndConditionEndData = "Terms %26 Conditions";

// API Methods
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpPutMethod = "PUT";
exports.httpPatchMethod = "PATCH";

// API EndPoints
exports.contactUsAPiContentType = "application/json";
exports.questionsApiEndPoint = "bx_block_help_centre/question_answer/";
exports.questionAnswerApiEndPoint = "bx_block_help_centre/question_answer/";
exports.getPrivacySettingsApiEndPoint = "account_block/accounts/";
exports.setPrivacySettingsApiEndPoint = "bx_block_privacy_setting/privacy_profile_setting";
exports.notificationSettingsEndPoint = "bx_block_notifsettings/setting"
exports.subjectsApiEndPoint = "bx_block_custom_forms/subjects";
exports.contactUsApiEndPoint = "bx_block_custom_forms/contact_form";
exports.termsAndConditionApiEndPoint = "bx_block_terms_and_conditions/terms_and_conditions";
exports.policiesApiEndPoint = "bx_block_privacy_setting/policy";
// mockData
exports.responseGetPlan = {
  "plan": {
    "id": 1,
    "name": "Kella Empower",
    "period_in_months": 12,
    "amount": 3750.0,
    "vat_amount": 398.0,
    "created_at": "2024-05-20T12:11:52.956Z",
    "updated_at": "2024-09-04T15:43:57.325Z",
    "title": "",
    "interval": "monthly",
    "features": [
      {
        "id": 1,
        "title": "accounting",
        "description": "accounting",
        "plan_id": 2,
        "created_at": "2024-08-29T13:06:18.385Z",
        "updated_at": "2024-08-29T13:06:18.385Z"
      },
      {
        "id": 2,
        "title": "subscription",
        "description": "subscription title",
        "plan_id": 2,
        "created_at": "2024-09-04T13:10:53.180Z",
        "updated_at": "2024-09-04T13:10:53.180Z"
      }
    ]
  },
  "status": "Active",
  "billing": {
    "id": 1,
    "account_id": 429,
    "plan_id": 2,
    "start_date": "2024-09-04",
    "end_date": "2025-09-04",
    "amount_paid": "3750.0",
    "currency": "eur",
    "promo_code": null,
    "discount": "0.0",
    "stripe_subscription_id": "test",
    "stripe_session_id": "test",
    "created_at": "2024-09-04T13:52:48.150Z",
    "updated_at": "2024-09-04T13:52:48.150Z",
    "active": true,
    "is_canceled": false
  },
  "is_canceled": false
}
exports.responseGetPlans = {
  plans: [
    {
      "id": 1,
      "name": "Kella Empower",
      "period_in_months": 12,
      "amount": 3750.0,
      "vat_amount": 398.0,
      "created_at": "2024-05-20T12:11:52.956Z",
      "updated_at": "2024-09-04T15:43:57.325Z",
      "title": "",
      "interval": "monthly",
      "features": [
        {
          "id": 1,
          "title": "accounting",
          "description": "accounting",
          "plan_id": 2,
          "created_at": "2024-08-29T13:06:18.385Z",
          "updated_at": "2024-08-29T13:06:18.385Z"
        },
        {
          "id": 2,
          "title": "subscription",
          "description": "subscription title",
          "plan_id": 2,
          "created_at": "2024-09-04T13:10:53.180Z",
          "updated_at": "2024-09-04T13:10:53.180Z"
        }
      ]
    },
    
  ]
}

exports.chipColorByState = {
  pending: {
    color: '#E1B667', background: '#FEF3C7',
  },
  completed: {
    color: '#059669', background: '#D1FAE5',
  },
  declined: {
    color: '#DC2626',
    background: '#FEE2E2',
  }
}
exports.responseApplyPromo = {
  success: true,
  discount: 10,
  message: "Promo code applied successfully.",
  plan: {
    id: 1,
    name: "Pro Plan",
    period_in_mounth: 12,
    amount: 1000,
    vat_amount: 200,
    interval: "yearly",
    title: "Pro Plan Yearly Subscription",
    features: [
      {
        title: "Unlimited Projects",
        description: "Create unlimited projects.",
        id: 101,
        plan_id: 1,
      },
      {
        title: "Priority Support",
        description: "24/7 priority customer support.",
        id: 102,
        plan_id: 1,
      },
    ],
  },
  promo_code: "SAVE10",
  order_summary: {
    plan: {
      id: 1,
      name: "Pro Plan",
      period_in_mounth: 12,
      amount: 1000,
      vat_amount: 200,
      interval: "yearly",
      title: "Pro Plan Yearly Subscription",
      features: [
        {
          title: "Unlimited Projects",
          description: "Create unlimited projects.",
          id: 101,
          plan_id: 1,
        },
        {
          title: "Priority Support",
          description: "24/7 priority customer support.",
          id: 102,
          plan_id: 1,
        },
      ],
    },
    payment_date: "2024-09-17",
    plan_end_date: "2025-09-17",
    plan_discount: 100,
    total_amount: 1100, // total after discount and VAT
    vat_amount: 200,
    promo_code: "SAVE10",
    promo_code_discount: 100, // promo code discount applied
  },
};
exports.billingResponse = {"04/09/2024 - 04/09/2025":[{"payment_date":"04/09/2024","promo_code":null,"total_cost":"3750.0","status":"completed"}]} 
// Customizable Area End