import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import moment from "moment";
import { SelectItem } from "./AddEventDetailController";

const color = ["#6bff65", "#6bff65", "#6bff65"];
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  allMenuFlag: boolean;
  allFilter: boolean;
  showMonthPickerModal: boolean;
  showMonth: boolean;
  date: Date;
  month: Date;
  eventDetailFlag: boolean;
  allEventData: Array<AllEvents>;
  allEventDataDateWise: Array<AllEvents>;
  selectedDate: string;
  allStartDate: string;
  allEndDate: string;
  isAuthozied: boolean;
  // totalAllEvent: CalendarEventReadable[];
  totalAllLocal: TotalAllLocal[];
  allLocalDataDateWise: TotalAllLocal[];
  token: string;
  Tabview: string;
  open: boolean;
  openFilter: boolean;
  eventMode: string;
  setSearchvalue: string;
  AllEventsresponse: any;
  viewdetailsData: any;
  cityDataList: any;
  selectedcity: any;
  setSearchCategoryvalue: any;
  categoryDataList: any;
  selectedcategory: any;
  eventPrice: any;
  errorTicket: boolean;
  TicketSucess: boolean;
  DateValue: any;
  newfinaltoken: string
  // Customizable Area End
}

interface MarkedDataObject {
  [key: string]: {
    dots: Array<string | { key: string; color: string }>;
    selected?: boolean;
  };
}

interface RebuildEventData {
  [key: string]: Array<AllEvents>;
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface AllEvents {
  id: number;
  title: string;
  time: string;
  date: string;
  latitude: string | null;
  longitude: string | null;
  assign_to: Array<SelectItem>;
  email_account_id: number;
  notify: string;
  repeat: string;
  notes: string;
  visibility: [];
  created_at: string;
  updated_at: string;
  event_type: string;
  address: string | null;
  custom_repeat_in_number: number | null;
  custom_repeat_every: number | null;
  assignee_email: [];
  visible_email: [];
}

interface AllEventResponse {
  data: RebuildEventData;
}

export interface TotalAllLocal {
  id?: string | null;
  type?: string | null;
  attributes?: {
    title?: string | null;
    time?: string | null;
    date?: string | null;
    latitude?: string | null;
    longitude?: string | null;
    email_account_id?: number | null;
    notify?: string | null;
    repeat?: string | null;
    notes?: string | null;
    created_at?: string | null;
    updated_at?: string | null;
    event_type?: string | null;
    assigned_to?: Array<SelectItem> | null;
    visibility?: Array<SelectItem> | null;
    user?: {
      data?: {
        id?: string | null;
        type?: string | null;
        attributes?: {
          full_name?: string | undefined;
          full_phone_number?: string | null;
          country_code?: string | null;
          phone_number?: string | null;
          email?: string | null;
          activated?: string | null;
          gender?: string | null;
          date_of_birth?: string | null;
          created_at?: string | null;
          updated_at?: string | null;
          device_id?: string | null;
          device_type?: string | null;
          unique_auth_id?: string | null;
          custom_gender?: string | null;
          profile_image?: string | null;
        };
      };
    };
  };
}

export interface MarkedLocalData {
  attributes: {
    event_type: string;
    date: string;
  };
}

interface ErrorResponse {
  error: string;
}

export interface SelectedDate {
  dateString: string;
}

export default class AllEventController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiGetAllEventId: string | null = "";
  getSearchCityCallId: string = "";
  getSearchCategoryCallId: string = "";
  apiGetMYEventId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      allMenuFlag: false,
      allFilter: false,
      showMonthPickerModal: false,
      showMonth: false,
      date: new Date(Date.now() + 6 * 1000),
      eventDetailFlag: false,
      allEventData: [],
      allEventDataDateWise: [],
      selectedDate: "",
      allStartDate: "",
      allEndDate: "",
      month: new Date(Date.now() + 6 * 1000),
      isAuthozied: false,
      // totalAllEvent: [],
      totalAllLocal: [],
      allLocalDataDateWise: [],
      token: "",
      Tabview: "allevents",
      open: false,
      viewdetailsData: {},
      openFilter: false,
      eventMode: "",
      setSearchvalue: "",
      AllEventsresponse: [],
      cityDataList: [],
      selectedcity: [],
      setSearchCategoryvalue: "",
      categoryDataList: [],
      selectedcategory: [],
      eventPrice: "",
      errorTicket: false,
      TicketSucess: false,
      DateValue: [],
      newfinaltoken: ""
      // Customizable Area End
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.setState({
      allStartDate: moment().format("YYYY-MM-DD"),
      allEndDate: moment().endOf("month").format("YYYY-MM-DD"),
    });

    await this.checkLocalCalendarAuthetication();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    if (this.state.token) {
      this.getAllEventAPI();
    }
    // Customizable Area Start
    const event = "allevents"
    this.getAllEventAPIS(event);

    const ticketpurchse = localStorage.getItem("eventPurchase");
    if (ticketpurchse == "1") {
      this.setState({ TicketSucess: true })
    }
    // Customizable Area End
  }

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token });

    }

    if (this.isRestApiResponse(message, responseJson)) {
      
      this.handleRestApiResponses(apiRequestCallId, responseJson);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  rebuildEventData = (data: RebuildEventData) => {
    let allEvents: Array<AllEvents> = [];

    Object.keys(data).map((eventType: keyof RebuildEventData) => {
      allEvents = allEvents.concat([], data[eventType]);
    });

    return allEvents;
  };

  isRestApiResponse(message: Message, responseJson: any): boolean {
    return responseJson && getName(MessageEnum.RestAPIResponceMessage) === message.id;
  }

  getAllEventAPI() {
    const token = localStorage.getItem("accessToken");
    this.setState({ newfinaltoken: token || "" })
  }

  handleRestApiResponses(apiRequestCallId: string, responseJson: any) {
    switch (apiRequestCallId) {
      case this.getSearchCityCallId:
        this.getSearchCityData(responseJson);
        break;
      case this.getSearchCategoryCallId:
        this.getSearchCategoryData(responseJson);
        break;
      case this.apiGetAllEventId:
        this.getAllEventsData(responseJson);
        break;
      default:
        break;
    }
  }

  getAllEventAPIS = async (event: any) => {

    const token = localStorage.getItem("accessToken");
    const header = {
      token: token,
    };


    const datestart = `${this.formatDateforAPi(this.state.DateValue[0])}`
    const dateend = `${this.formatDateforAPi(this.state.DateValue[1])}`
    const myeventset = event == "myevents" ? "/my_events" : ""

    const categoryid = []
    for (const selected of this.state.selectedcategory) {
      categoryid.push(selected.id);
    }

    const city = this.state.selectedcity.map((cityObj: any) => cityObj.value);


    let urlweb = `bx_block_events/events${myeventset}?`;

    if (datestart) {
      urlweb += `from_date=${datestart}`
    }
    if (dateend) {
      urlweb += `&to_date=${dateend}`
    }

    if (categoryid.length > 0) {
      const queryStringParams = encodeURIComponent(JSON.stringify(categoryid))
      urlweb += `&category_id=${queryStringParams}`
    }
    if (city.length > 0) {
      const cityParam = encodeURIComponent(JSON.stringify(city));
      urlweb += `&city=${cityParam}`;
    }

    if (this.state.eventMode && this.state.eventMode.length > 0) {
      urlweb += `&event_type=${this.state.eventMode}`
    }

    if (this.state.eventPrice && this.state.eventPrice.length > 0) {
      let finalValuePrice;
      if (this.state.eventPrice === "Paid") {
        finalValuePrice = true;
      } else if (this.state.eventPrice === "Free") {
        finalValuePrice = false;
      } else {
        finalValuePrice = "";
      }

      urlweb += `&is_paid=${finalValuePrice}`
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetAllEventId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${urlweb}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  checkLocalCalendarAuthetication = async () => {

  };

  handleDetailsOpen = (data: any) => {
    this.setState({ viewdetailsData: data })
    this.setState({ open: true })
  }

  drawerClose = () => {
    this.setState({ open: false })
  }

  toggleDrawer = (data: boolean) => {
    this.setState({ open: data })
  }
  handleFilterDrawerOpen = () => {
    this.setState({ openFilter: true })
  }
  toggleDrawerfilter = () => {
    this.setState({ openFilter: false })
  }

  drawerClosefilter = () => {
    this.setState({ openFilter: false })
  }

  handlesearchCity = (event: any) => {
    this.handleSearchcitydata(event)
    this.setState({ setSearchvalue: event.target.value })
  }

  handleSearchcitydata = (data: any) => {
    const token = localStorage.getItem("accessToken")

    const getCityMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSearchCityCallId = getCityMessage.messageId;

    getCityMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_posts/list_cities?search=${data.target.value}`
    );

    getCityMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": "application/json",
        token: `${token}`,
      })
    );

    getCityMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `GET`
    );

    runEngine.sendMessage(getCityMessage.id, getCityMessage);
  }

  handleGetEventMode = (event: any) => {
    this.setState({ eventMode: event.target.value })
  }

  handleGetEventPrice = (event: any) => {
    this.setState({ eventPrice: event.target.value })
  }

  handlesearchCategoryData = (event: any) => {
    this.handleSearchcategory(event)
    this.setState({ setSearchCategoryvalue: event.target.value })
  }

  handleSearchcategory = (data: any) => {
    const token = localStorage.getItem("accessToken")

    const getSearchcategoryconnectionMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSearchCategoryCallId = getSearchcategoryconnectionMessage.messageId;

    getSearchcategoryconnectionMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories/categories?search=${data.target.value}`
    );

    getSearchcategoryconnectionMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": "application/json",
        token: `${token}`,
      })
    );

    getSearchcategoryconnectionMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `GET`
    );

    runEngine.sendMessage(getSearchcategoryconnectionMessage.id, getSearchcategoryconnectionMessage);
  }

  getSearchCityData = (responseJson: any) => {
    this.setState({ cityDataList: responseJson.data })
  }

  getvaluemembercheck = (elementdata: any, data: any) => {

    if (elementdata.target.checked) {
      const IteamIndex2 = this.state.selectedcity.findIndex((iteam: any) => iteam.id === data.id);


      if (IteamIndex2 < 0) {
        const storetype = [...this.state.selectedcity, { id: data.id, value: data.name }]
        this.setState({ selectedcity: storetype });
      }
    } else {
      const deleteSelectedType = this.state.selectedcity.filter((element: any) => {
        return element.id !== data.id
      })

      this.setState({ selectedcity: deleteSelectedType });
    }
  }

  getvaluecategorycheck = (elementdata: any, data: any) => {
    if (elementdata.target.checked) {
      const IteamIndex2 = this.state.selectedcategory.findIndex((iteam: any) => iteam.id === data.id);

      if (IteamIndex2 < 0) {
        const storetype = [...this.state.selectedcategory, { id: data.id, value: data.attributes.name }]
        this.setState({ selectedcategory: storetype });
      }
    } else {
      const deleteSelectedType = this.state.selectedcategory.filter((element: any) => {
        return element.id !== data.id
      })

      this.setState({ selectedcategory: deleteSelectedType });
    }
  }

  handleselectmemberDelete = (elementdata: number) => {
    const deleteSelectedType = this.state.selectedcity.filter((element: any, index: number) => {
      return index !== elementdata
    })
    this.setState({ selectedcity: deleteSelectedType });
  }

  handleselectcategoryDelete = (elementdata: number) => {
    const deleteSelectedType = this.state.selectedcategory.filter((element: any, index: number) => {
      return index !== elementdata
    })
    this.setState({ selectedcategory: deleteSelectedType });
  }

  removesearchcitydata = () => {
    this.setState({ setSearchvalue: "" })
    this.setState({ cityDataList: [] })
  }

  getSearchCategoryData = (responseJson: any) => {
    this.setState({ categoryDataList: responseJson.data })
  }

  removesearchcategorydata = () => {
    this.setState({ setSearchCategoryvalue: "" })
    this.setState({ categoryDataList: [] })
  }

  handleTabChange = (event: any) => {

    this.getAllEventAPIS(event)

    this.setState({ Tabview: event })

  }

  getAllEventsData = (responseJson: any) => {
    const filterForSearchEvent = localStorage.getItem("eventsearchId");
    if(filterForSearchEvent){
      const response = responseJson.data.filter((element:any,index:any)=>{
        return element.id == filterForSearchEvent
      });
      
      this.setState({ AllEventsresponse: response })
    }else{
      this.setState({ AllEventsresponse: responseJson.data })
    }
  }

  handleApplyfilter = () => {

    this.getAllEventAPIS(this.state.eventMode);
    this.setState({ openFilter: false })
  }

  handleGoingPurchase = (data: any) => {
    if (data.attributes.tickets_left < 1 || data.attributes.tickets_left == null) {
      this.setState({ errorTicket: true });
      localStorage.removeItem("eventsearchId");
    } else {
      localStorage.setItem("event_id", data.id);
      localStorage.removeItem("eventsearchId");
      const msg = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), 'purchaseEvent');
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg)
    }
  }

  handleClose = () => {
    this.setState({ errorTicket: false })
  }

  handleCloseSucess = () => {
    localStorage.removeItem("eventPurchase")
    this.setState({ TicketSucess: false })
  }

  getDateRangeValue = (data: any) => {
    this.setState({ DateValue: data })
  }
  formatDate = (date: any) => {
    const jsDate = date?.toDate();
    if (!jsDate) return "";


    const day = String(jsDate.getDate()).padStart(2, '0');


    const month = jsDate.toLocaleDateString('en-US', { month: 'long' });
    const year = jsDate.getFullYear();


    return `${day} ${month} ${year}`;
  };

  formatDateforAPi = (date: any) => {
    const jsDate = date?.toDate();
    if (!jsDate) return "";

    const year = jsDate.getFullYear();
    const month = String(jsDate.getMonth() + 1).padStart(2, '0');
    const day = String(jsDate.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  };

  // Customizable Area End
}
