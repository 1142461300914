import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  styled,
  Select,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Chip,
  MenuItem,
} from "@material-ui/core";
import { ArrowBack,ExpandMore } from "@material-ui/icons";
const configJSON = require("./config");

// Customizable Area End

import BillingController, { Props } from "./BillingController.web";

class Billing extends BillingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
        <Box>
            <StyledHeaderWrapper>
                <ArrowBack style={{marginLeft: '12px'}} onClick={this.handleGoBack}/>
                <Typography className="pageTitle">Subscription Billing</Typography>
            </StyledHeaderWrapper>
            <StyledTableWrapper>
                <Box className='tableTitle'>
                    <Typography style={webStyle.TableTitleText}>KELLA Empower - Monthly Plan</Typography>
                    <Box>
                        <StyledSelect disableUnderline value={this.state.selectedDate} onChange={this.handleChangeDate} IconComponent={ExpandMore}>
                            {this.state.dates.map((date) => (<MenuItem key={date} value={date}>{date}</MenuItem>))}
                        </StyledSelect>
                    </Box >

                </Box>
                <StyledTable>
                    <TableHead>
                        <TableRow>
                            <TableCell>Payment Date</TableCell>
                            <TableCell>Promo Code</TableCell>
                            <TableCell>Total Const</TableCell>
                            <TableCell >Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.paymentsBySelectedDate.map(payment => (
                            <TableRow>
                            <TableCell>{payment.payment_date}</TableCell>
                            <TableCell>{`- ${payment.promo_code ? "&#x20AC;"+payment.promo_code: ''}`}</TableCell>
                            <TableCell>&#x20AC; {payment.total_cost}+VAT</TableCell>
                            <TableCell className='status'> <Chip size="small" style={{color: configJSON.chipColorByState[payment.status]?.color, background: configJSON.chipColorByState[payment.status]?.background, fontWeight: 'bold'}} label={payment.status}/> </TableCell>
                        </TableRow>
                        ))}
                        
                    </TableBody>
                </StyledTable>
            </StyledTableWrapper>

        </Box>      
      // Customizable Area End
    );
  }
}

// Customizable Area Start

export default Billing;

const StyledTable = styled(Table)({
    marginTop:'10px',
    marginBottom: '50px',
    '& .MuiTableCell-head': {
        borderBottomColor: '#E2E8F0',
        paddingBottom: '0px',
        color: '#A8CEBE',
        fontWeight: 'bold',
        fontSize: '12px'
    },
    '@media (max-width: 900px)': {
        '& .MuiTableCell-body, & .MuiTableCell-head,': {
            padding: '10px 0'
        },
    },
})
const StyledHeaderWrapper = styled(Box)({
    marginTop:'5px',
    '& .pageTitle': {
        color:'#14362E',
        fontSize:'24px',
        fontWeight:'bold',
        paddingLeft:'80px',
        marginTop:'24px'
    },
    '@media (max-width: 600px)': {
        display:'flex',
        alignItems: 'center',
        '& .pageTitle': {
            marginTop: '0px'
        }
    },
  

})
const StyledTableWrapper = styled(Box)({
    maxWidth:'750px',
    margin:' 0 auto',
    padding: '5px',
    '& .tableTitle': {
        display:'flex',
        justifyContent: 'space-between',
        marginTop:'100px',
        padding: '0 20px',
        alignItems: 'center'
    },
    '@media (max-width: 600px)': {
        '& .tableTitle': {
            justifyContent: 'flex-start',
            flexDirection: 'column',
            marginTop:'20px'
        }, 
    },
});
const StyledSelect = styled(Select)({
    border: '1px solid #CBD5E1',
    background: 'transparent',
    borderRadius: '8px',
    fontFamily: 'MyFont',
    padding: '1px 8px',
    "& .MuiSelect-icon": {
        scale:'0.7',
        transform: 'translateY(-3px)'
    }
});
const webStyle = {
    TableTitleText: {
        color:'#14362E',
        fontWeight:700
    },

};

// Customizable Area End
