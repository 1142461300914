import React from "react";

import {
  Box,
  Button,
  Typography,
  // Customizable Area Start
  styled,
  Modal,
  Card,
  Input,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { ArrowBack, } from "@material-ui/icons";
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CloseIcon from "@material-ui/icons/Close";
import SubscriptionCard from "../../../components/src/SubscriptionCard.web";

// Customizable Area End

import EditPaymentController, {
  Props,
} from "./EditPaymentController.web";
import ResponsiveDrawer from "../../../components/src/ResponsiveDrawer.web";


export default class EditPayment extends EditPaymentController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  selectSubscription =()=>{
    return(
      <>
        <StyledCardWrapper >
            {this.state.plans.map(plan => 
              <SubscriptionCard
                features={plan.features}
                amount={plan.amount}
                interval={plan.interval}
                selectable
                isSelected={this.state.selectedPlan === plan.id}
                onSelect={this.handleSelectPlan.bind(this, plan.id)}
                handleViewDetails={this.handleViewDrawer1.bind(this, plan.id)}
              />
            )}
       
            <Box sx={{maxWidth:'450px', margin: '0 auto', flexDirection:'column', marginTop: 'auto'}}>
              <StyledButton disabled={this.state.selectedPlan === 0} variant="contained" fullWidth onClick={this.handlePlanSelect}>Change Subscription</StyledButton>
              <StyledOutlineButton disabled={this.state.selectedPlan === 0} fullWidth  onClick={this.handleChangeCancelModal.bind(null, true)}>Cancel Subscription</StyledOutlineButton>
            </Box>
        </StyledCardWrapper>
        {this.cancelModal()}
        {this.viewDetailDrawerweb()}
      </>
    )
  }
  changeSubscription = () => {
    const selectedPlanData = this.state.plans.find(plan => plan.id === this.state.selectedPlan)
    return (
      <Box style={{
        maxWidth:'450px',
        width:'100%',
        padding:'0 5px',
        boxSizing:'border-box',
        margin: '0 auto',
        marginTop: "50px",
      }}>
        <StyledChngeSubscriptionContentWrapper >  
            <SubscriptionCard
                features={selectedPlanData?.features}
                amount={selectedPlanData?.amount}
                interval={selectedPlanData?.interval}
                handleViewDetails={this.handleViewDrawer1.bind(this, selectedPlanData!.id)}
                name={selectedPlanData?.name}
                title={selectedPlanData?.title}
              />
            <Box onClick={this.handlePromo} style={{ width:'100%', alignItems:'center', marginTop: '38px', display:' flex', justifyContent:' space-between', borderTop:'1px solid #E2E8F0', borderBottom:'1px solid #E2E8F0'}}>
              <Box style={{display:'flex', gap: '10px', padding: '20px 0', }}>
                <CardGiftcardIcon/>
                <Typography style={{fontWeight:"bold", fontSize:"16px",color: '#0F172A'}}>
                  Promo code
                </Typography>
              </Box>
              <ArrowForwardIosIcon style={{fontSize:'15px !important'}}/>
            </Box>
            <Box hidden={!this.state.couponDetails}>
              <Box style={{marginTop:'30px', width:'100%'}}>
                <Typography style={{fontWeight:'bold', fontSize:'16px', color:'#334155'}}>Coupon details</Typography>
              </Box>
              <Box style={{width:'100%'}}>
                <Box style={{marginBottom:'12px', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                  <Typography style={ {fontSize:'16px',}}>Promo code</Typography>
                  <Typography style={{fontWeight:'bold', fontSize:'14px', }}>12333</Typography>
                </Box>
                <Box style={{marginBottom:'12px', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                  <Typography style={ {fontSize:'16px',}}>Amount off</Typography>
                  <Typography style={{fontWeight:'bold', fontSize:'14px', }}>- €60</Typography>
                </Box>
                <Box style={{marginBottom:'12px', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                  <Typography style={ {fontSize:'16px',}}>Total amount</Typography>
                  <Typography style={{fontWeight:'bold', fontSize:'14px',color: '#73A58E' }}>€ 3,405</Typography>
                </Box>
              </Box>
            </Box>

            <Box sx={{flexDirection:'column', marginTop: 'auto', width:'100%', marginBottom:'10px'}}>
              {this.state.changeError && 
                <Box style={{
                  height: "48px",  
                  borderLeft: `4px solid #D1584C`,
                  borderRadius: "4px",
                  backgroundColor: "#EEC3BE",
                  padding: "12px 8px 12px 16px",
                  display:"flex",
                  color:"#DC2626",
                  marginBottom: '8px'
                }}>
                  <Typography  className="boxTextStyle" style={{color:'#DC2626'}}>{this.state.changeError}</Typography>
                  <Box style={{height: "24px", width:"24px", padding:"12px" }}>
                    <CloseIcon style={{height: "16px", width:"16px"}} onClick={this.closeChangeError}/>
                  </Box>
                </Box>
              }
              <StyledButton variant="contained" fullWidth onClick={this.handleChangeSubscription}>Change Subscription</StyledButton>
            </Box>
        </StyledChngeSubscriptionContentWrapper>
        {this.subscriptionDrawerWeb()}
        {this.viewDetailDrawerweb()}
        {this.showSuccessModal()}

      </Box>
    )
  }
  showSuccessModal = () => {
    return (<Modal open={this.state.successModal} onClose={this.closeSuccessModal}>
      <StyledModalCard>
        <Typography className="cardTitle">Membership change request has been received</Typography>
        <Typography className="cardDescription">Admin is reviewing your request for subscription change, and will get back to you soon.</Typography>
        <Button variant="contained" fullWidth className="cardButton" onClick={this.closeSuccessModal}>Ok</Button>
      </StyledModalCard>
    </Modal>)
  }
  viewDetailDrawerweb = () => {
    const viwedPlanData = this.state.plans.find(plan => plan.id === this.state.viewDrawerId);

    return (
      <ResponsiveDrawer open={this.state.viewDetailDrawer1} handleClose={this.handleViewDrawerClose} title='Plan details'>
        <StyledViewDetailWraper>
          <SubscriptionCard
            name={viwedPlanData?.name}
            features={viwedPlanData?.features}
            amount={viwedPlanData?.amount}
            title={viwedPlanData?.title}
            interval={viwedPlanData?.interval}
          />
      </StyledViewDetailWraper>
      </ResponsiveDrawer>
    )
  }
  cancelModal = () => {
    return (<Modal open={this.state.cancelModal}>
      <Card style={{maxWidth:'500px', width:'80%', padding: '40px 24px 20px 24px', position: 'absolute', top:'50%', bottom:'50%', left:'50%', right:'50%', transform:'translate(-50%,-50%)', minHeight:'190px'}}>
          <Typography style={{color:"#14362E", fontSize:'24px', fontWeight: 700, textAlign:'center', marginBottom:' 16px'}}>Are you sure you want to cancel your subscription?</Typography>
          <Typography style={{color:"#0F172A", fontSize:'16px', fontWeight: 500, textAlign:'center', marginBottom:' 16px'}}>Once your subscription is cancelled, you can no longer use the KELLA app</Typography>

          <Box style={{display:'flex', gap:'12px'}}>
              <Button fullWidth variant="outlined" style={{borderColor: '#73A58E', color: '#14362E', fontWeight: 700, textTransform: 'none', fontSize:"16px"}} onClick={this.handleChangeCancelModal.bind(this, false)}>Go Back</Button>
              <Button fullWidth variant="contained"style={{ fontSize:'16px',background: '#14362E', color: 'white', fontWeight: 700, textTransform: 'none'}}  onClick={this.handleCancelSubscription}>Cancel</Button>
          </Box>
      </Card>
    </Modal>)
  }
  subscriptionDrawerWeb=()=>{
    return(
      <ResponsiveDrawer  handleClose={this.handlepromoClose} title='Promo Code' open={this.state.isPromoOpen}>
            <DrawerContent>
              <Box style={{
                padding: "0px 28px",
                display:"flex",
                flexDirection:'column',
                justifyContent:'space-between',
                height:'100%'
              }}>
                {this.state.error && <Box style={{
                  height: "48px",  
                  borderLeft: `4px solid #D1584C`,
                  borderRadius: "4px",
                  backgroundColor: "#EEC3BE",
                  padding: "12px 8px 12px 16px",
                  display:"flex",
                  color:"#DC2626"
                }}>
                  <Typography  className="boxTextStyle" style={{color:'#DC2626'}}>{this.state.error}</Typography>
                  <Box style={{height: "24px", width:"24px", padding:"12px" }}>
                    <CloseIcon style={{height: "16px", width:"16px"}} onClick={this.handleCloseError}/>
                  </Box>
                </Box>
                }
                {this.state.couponDetails && 
                <Box style={{
                  height: "48px",  
                  borderLeft: `4px solid ${this.state.couponDetails.success ? "#73A58E":"#D1584C"}`,
                  borderRadius: "4px",
                  backgroundColor: `${this.state.couponDetails.success ? "#D4E5DE":"#EEC3BE"}`,
                  padding: "12px 8px 12px 16px",
                  display:"flex",
                  color:`${this.state.couponDetails.success ? "#73A58E":"#DC2626"}`
                }}>
                  <Typography  className="boxTextStyle">{this.state.couponDetails.message}</Typography>
                  <Box style={{height: "24px", width:"24px", padding:"12px" }}>
                    <CloseIcon style={{height: "16px", width:"16px"}} onClick={this.handleCloseMessage}/>
                  </Box>
                </Box>}
                
                <Box style={{height: "86px", gap:"12px !important", marginTop: "12px"}}>
                  <Typography
                    className="Typostyle"
                    style={{ 
                      width:"109px",
                      height: "26px",
                      fontFamily:"Montserrat",
                      marginBottom:'12px'
                    }}
                  >Promo code</Typography>
                  <Input
                    onChange={this.handleChangePromoCode}
                    fullWidth
                    disableUnderline
                    value={this.state.promoCode}
                    style={{border: '1px solid #CBD5E1', borderRadius: '8px', padding: '8px'}}
                    endAdornment={<CardGiftcardIcon
                      style={{ height: "20.31px",left:"2.41px",color:"#64748B", width:"21.38x",top:"2.67px",}}
                    />}
                  />

                </Box>
                <hr style={{ border: "1px solid #CBD5E1", marginTop: '24px'}}/>
                <Box style={{height: "595px"}} hidden={!this.state.couponDetails}> 
                  <Box style={{height: "48px", border:"0p 0px 1px 0px", padding:"12px, 0px, 12px, 0px"}}>
                    <Typography style={{height: "24px"}} className="Typostyle"
                    >Coupon details</Typography>
                  </Box>
                  <Box style={{height: "118px",
                    padding:"20px, 16px, 20px, 16px",
                    background: "#F8FAFC",
                  }}
                  >
                    <Box style={{justifyContent:"space-between", height: "18px",  display:"flex", margin:"15px"}}>
                      <Box style={{height:"18px",}}>
                      <Typography className="boxTypoStyle1">{this.state.promoCode}</Typography>
                      </Box>
                      <Box style={{height:"18px", }}>
                      <Typography className="boxTypoStyle2"></Typography>
                      </Box>
                      
                    </Box>
                    <Box style={{display:"flex",margin:"15px", height: "18px", justifyContent:"space-between", }}>
                      <Typography className="boxTypoStyle1">Amount off</Typography>
                      <Typography className="boxTypoStyle2" style={{color:"#73A58E"}}>-&#x20AC; {this.state.couponDetails?.order_summary.plan_discount}</Typography>
                    </Box>
                    <Box style={{height: "18px", display:"flex", justifyContent:"space-between", margin:"15px"}}>
                      <Typography className="boxTypoStyle1">Total Amount</Typography>
                      <Typography className="boxTypoStyle2">&#x20AC; {this.state.couponDetails?.order_summary.total_amount}</Typography>
                    </Box>
                  </Box>
                </Box>
                <Button className="buttonStyle" variant="contained" onClick={this.applyPromocode}>Add Promo Code</Button>
              </Box>
            </DrawerContent>
          </ResponsiveDrawer>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
        <Box>
            <StyledHeaderWrapper>
                <ArrowBack style={{marginLeft: '12px'}} onClick={this.handleGoBack}/>
                <Typography className="pageTitle">Membership Billing</Typography>
            </StyledHeaderWrapper>
            {this.state.step === 1 && this.selectSubscription()}
            {this.state.step === 2 && this.changeSubscription()}
        </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const StyledCardWrapper = styled(Box)({
  marginTop: "50px",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
  gap: "25px",
  '& .subscriptionOverride': {
    boxShadow: "0px 8px 32px 0px #0000000F",
    borderRadius: "8px 8px 32px 8px",
  }
});
const StyledButton = styled(Button)({
  backgroundColor: "#14362E",
  color:"#FFFFFF",
  textTransform: 'unset' as const,
  fontSize:'16px',
  fontWeight: 700
});
const StyledViewDetailWraper = styled(Box)({
  padding: "0px 28px 0px 28px",
  '& .subscriptionOverride': {
    border: '2px solid #73A58E'
  }
})
const StyledChngeSubscriptionContentWrapper = styled(Box)({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
  gap: "25px",
  '& .subscriptionOverride': {
    border: '2px solid #73A58E'
  }
})
const StyledOutlineButton = styled(Button)({
  color: "#14362E",
  fontSize:'16px',
  textTransform: 'unset' as const,
  fontWeight: 'bold'
})
const StyledHeaderWrapper = styled(Box)({
    marginTop:'5px',
    '& .pageTitle': {
        color:'#14362E',
        fontSize:'24px',
        fontWeight:'bold',
        paddingLeft:'80px',
        marginTop:'24px'
    },
    '@media (max-width: 900px)': {
        display:'flex',
        alignItems: 'center',
        '& .pageTitle': {
            marginTop: '0px'
        }
    },
  

})
const StyledModalCard = styled(Card)({
  margin:'0 auto',
  marginTop: '100px',
  maxWidth:'540px',
  padding: '24px',
  paddingTop: '24px',
  boxSizing: 'border-box',
  borderBottomRightRadius:'32px',
  "& .cardTitle": {
    fontSize: '24px',
    fontWeight: 700,
    color: '#14362E',
    marginBottom:'16px',
    textAlign: 'center'

  },
  "& .cardDescription": {
    marginBottom: '48px',
    fontSize:'16px',
    fontWeight:'500',
    textAlign: 'center'
  },
  "& .cardButton": {
    background: '#14362E',
    color: 'white'

  },
  '@media (max-width: 600px)': {
    width:'90%'
  },

})
const DrawerContent = styled(Box)({

  '& .TextStyle': {
    fontSize:"24px",
    fontFamily: "MyFont !important",
    fontWeight:500,
    lineHeight:"32px",
  },
  '& .TextStyle2': {
    fontSize:"20px",
    fontFamily: "MyFont !important",
    fontWeight:500,
    lineHeight:"28px",
  },
  '& .boxTextStyle': {
    height: "18px",
    width: "372px",
    paddingTop: "3%",
    fontSize:"12px",
    fontFamily: "MyFont !important",
    fontWeight:400,
    lineHeight:"18px",
    color: "#73A58E"
  },
  '& .Typostyle':{
    fontFamily:"MyFont !imporatnt",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    color: "#334155",
  },
  '& .boxTypoStyle1':{
   
    fontFamily:"MyFont !important",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "8px",
  },
  '& .boxTypoStyle2':{
    fontFamily:"MyFont !important",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "8px",
  },
  '& .buttonStyle':{
    height: "56px",
    backgroundColor: "#14362E",
    color:"#FFFFFF",
    marginTop:'auto',
    fontWeight:'bold',
    textTransform:'unset'
  },
  '& .buttonStyle2':{
    height: "56px",
    margin:'15px',
    width: "350px",
    boderRadius:"8px",
    backgroundColor: "#14362E",
    color:"#FFFFFF",
    top:"79%",
  },
  '& .mainViewDetailDrawerStyle':{
    height: "1024px",
    width: "484px",
    left:"956px",
    borderRadiusadius:"24px, 0px, 0px, 0px",
    Padding:"0px, 0px, 40px, 0px",
    Gap:"24px",
    boxShadow: "0px 8.31px 33.24px 0px #0000000F",
  },
  
  '& .viewdrawerStyle':{
    height: "73px",
    width: "484px",
    padding: "12px 28px 12px 28px !important"

  },
  '& .viewdrawerBoxStyle':{
    height: "468px",
    marginTop:"25px",
    gap: "6px !important",
    border : "1px solid #73A58E",
    borderRadius: '8px 8px 32px 8px'
  },
  '& .viewdrawerBoxStyle2':{
    height: "480px",
    marginTop:"25px",
    gap: "6px !important",
    border : "1px solid #73A58E",
    borderRadius: '8px 8px 32px 8px'
  },
  '& .viewDrawerTypoStyle':{
    fontFamily:"MyFont !important",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
  },
  '& .viewDrawerButton':{
    margin:'15px',
    backgroundColor: "#14362E",
    color:"#FFFFFF",
    top:"79%",
    textTransform: 'unset'
  },
  '& .viewDrawerButton2':{
    height: "56px",
    margin:'15px',
    boderRadius:"8px",
    backgroundColor: "#14362E",
    color:"#FFFFFF",
    top:"79%",
    right:"20px"
  },
})
// Customizable Area End
