import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  TextField,
  Typography,
  InputLabel,
  Grid,
  InputAdornment,
  IconButton,
  Drawer,
  List,
  Stepper,
  Step,
  StepLabel,
  ListItem,
  ListItemText,
  Radio,
  Modal,
  Container
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { logo, women, groupstepper } from "./assets";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import SearchIcon from "@material-ui/icons/Search";
import { Alert } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
const configJSON = require("./config");
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

import AccountDetailsController, { Props } from "./AccountDetailsController";

class AccountDetail extends AccountDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  validateFields = () => {
    const { companyTextFieldValue, roleTextFieldValue, industryTextFieldValue } = this.state;
    const companyFieldValid = companyTextFieldValue.trim() !== "";
    const roleFieldValid = roleTextFieldValue.trim() !== "";
    const industryFieldValid = industryTextFieldValue.trim() !== "";

    this.setState({
      companyFieldValid,
      roleFieldValid,
      industryFieldValid,
    });

    return companyFieldValid && roleFieldValid && industryFieldValid;
  };

  showBackandNextButton = () => {
    return (
      <>
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "10px",
            marginTop: "10%",
          }}
        >
          <Box style={{ width: "234px", height: "56px" }}>
            <Button
              variant="outlined"
              fullWidth
              style={{
                border: "1px solid #14362E",
                borderRadius: "8px",
                height: "45px",
                color: "#14362E",
                fontWeight: 700,
                fontFamily: "MyFont",
                letterSpacing: "1px",
                fontSize: "16px",
                textTransform: "capitalize"
              }}
              onClick={this.handleGoBack}
            >
              Back
            </Button>
          </Box>
          <Box style={{ width: "234px", height: "56px" }}>
            <Button
              variant="contained"
              fullWidth
              style={{
                backgroundColor: "#14362E",
                borderRadius: "8px",
                height: "45px",
                color: "white",
                fontWeight: 700,
                fontFamily: "MyFont",
                letterSpacing: "1px",
                fontSize: "16px",
                textTransform: "capitalize",
              }}
              onClick={() => {
                if (this.validateFields()) {
                  this.handleNextButtonClick();
                }
              }}
            >
              Next
            </Button>
            {this.showErrorModal()}
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Box style={{ marginTop: "-3%" }}>
            <Typography
              style={{
                fontWeight: 400,
                fontSize: "16px",
                fontFamily: "MyFont",
                letterSpacing: "1px",
              }}
            >
              Already have an account?{" "}
              <a
                href="/EmailAccountLoginBlock"
                style={{
                  fontSize: "16px",
                  fontWeight: 700,
                  color: "#14362E",
                  textDecoration: "none",
                }}
              >
                Log in
              </a>
            </Typography>
          </Box>
        </Grid>
      </>
    );
  };

  showErrorModal = () => {
    return (
      <Box>
        <Modal
          open={this.state.errorModal}
          onClose={this.handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Box sx={{ ...webpageStyle.modalStyle }}>
            {Object.keys(this.state.errorMessage).map(val => this.state.errorMessage[val])}
          </Box>
        </Modal>
      </Box>
    )
  };

  roleWebDrawer = () => {
    return (
      <Drawer
        anchor="right"
        open={this.state.roleWebDrawerOpen}
        onClose={this.toggleWebRoleDrawer(false)}
        PaperProps={{
          style: {
            borderTopLeftRadius: "20px",
            width: "480px",
            padding: "5px",
            boxShadow: "5px 7px 8px 0px !important",
          },
        }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box style={{ display: "flex", padding: "10px" }}>
            <Typography
              style={{
                fontSize: "24px",
                fontWeight: 600,
                color: "#14362E",
                fontFamily: "MyFont",
                letterSpacing: "1.4",
              }}
            >
              {configJSON.accountDetailsHeading2}
            </Typography>
          </Box>
          <Box>
            <IconButton
              style={{ right: "10px" }}
              onClick={this.toggleWebRoleDrawer(false)}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>

        <Box style={{ display: "flex", padding: "10px" }}>
          <TextField
            fullWidth
            placeholder="Search role"
            data-test-id="web-search"
            variant="outlined"
            error={this.state.roleError}
            value={this.state.searchRoleQuery}
            onChange={this.handleRoleSearch}
            InputProps={{
              style: { borderRadius: "8px", marginTop: "5px" },
              endAdornment: this.state.searchRoleQuery && (
                <InputAdornment position="end">
                  <IconButton onClick={this.searchRoleQueryfunc}>
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ),
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box style={{ overflow: "auto", marginTop: "30px" }}>
          <List>
            {this.state.role_list.map((item: any, index: number) => {
              const searchRoleQuery = this.state.searchRoleQuery.toLowerCase();
              const roleName = item.name.toLowerCase();
              if (!searchRoleQuery || roleName.includes(searchRoleQuery)) {
                return (
                  <React.Fragment key={index}>
                    <ListItem
                      button
                      onClick={() => this.handleSelectRole(item)}
                      style={{ cursor: "pointer" }}
                    >
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <Box>
                          <ListItemText primary={item.name} />
                        </Box>
                        <Box>
                          <Radio
                            data-test-id="webRadioBtn"
                            checked={this.state.selectedRole === item}
                            onChange={() => this.handleSelectRole(item)}
                            style={{ color: "green" }}
                          />
                        </Box>
                      </Box>
                    </ListItem>
                    <Box margin="auto"
                      width="90%"
                    >
                      <hr style={{ border: "none", borderBottom: "1px solid #CBD5E1", width: "100%", margin: "0", }} />
                    </Box>
                  </React.Fragment>
                );
              } else {
                return null;
              }
            })}
          </List>
        </Box>
        <Box mr={2} ml={2} pr={2} pl={2}>
          <Button
            fullWidth
            variant="contained"
            data-test-id="roleButton"
            style={{
              height: "45px",
              color: "white",
              fontWeight: 700,
              fontSize: "16px",
              borderRadius: "8px",
              fontFamily: "MyFont",
              letterSpacing: "1px",
              backgroundColor: "#14362E",
              textTransform: "capitalize",
              marginTop: "20%",
              marginBottom: "5%"
            }}
            onClick={this.handleRoleSelectButtonWeb}
          >
            Select
          </Button>
        </Box>
      </Drawer>
    )
  };

  roleMobileDrawer = () => {
    return (
      <Drawer
        anchor="right"
        open={this.state.roleMobileDrawerOpen}
        onClose={this.toggleMobileRoleDrawer(false)}
        PaperProps={{
          style: {
            width: "100%",
            borderTopRightRadius: "20px",
            marginTop: "19%",
          },
        }}
      >
        <Box
          style={{
            alignItems: "center",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box style={{ display: "flex", padding: "10px" }}>
            <Typography
              style={{
                color: "#14362E",
                fontFamily: "MyFont",
                letterSpacing: "1.4",
                fontSize: "24px",
                fontWeight: 600,
              }}
            >
              {configJSON.accountDetailsHeading2}
            </Typography>
          </Box>
          <Box>
            <IconButton
              style={{ right: "10px" }}
              onClick={this.toggleMobileRoleDrawer(false)}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>

        <Box style={{ display: "flex", padding: "10px" }}>
          <TextField
            fullWidth
            data-test-id="web-search"
            variant="outlined"
            placeholder="Search role"
            value={this.state.searchRoleQuery}
            onChange={this.handleRoleSearch}
            error={this.state.roleError}
            InputProps={{
              style: {
                borderRadius: "8px",
                marginTop: "5px"
              },
              endAdornment: this.state.searchRoleQuery && (
                <InputAdornment position="end">
                  <IconButton onClick={this.searchRoleQueryfunc}> <CloseIcon /> </IconButton>
                </InputAdornment>),
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton> <SearchIcon /> </IconButton>
                </InputAdornment>),
            }} />
        </Box>
        <Box style={{
          overflow: "auto",
          marginTop: "30px"
        }}
        >
          <List>
            {this.state.role_list.map((item: any, index: number) => {
              const searchRoleQuery = this.state.searchRoleQuery.toLowerCase();
              const roleName = item.name.toLowerCase();
              if (!searchRoleQuery || roleName.includes(searchRoleQuery)) {
                return (<React.Fragment key={index}>
                  <ListItem
                    button
                    onClick={() => this.handleSelectRole(item)}>
                    <Box style={{
                      display: "flex", alignItems: "center",
                      justifyContent: "space-between", width: "100%"
                    }}
                    >
                      <Box>
                        <ListItemText primary={item.name} />
                      </Box>
                      <Box>
                        <Radio
                          data-test-id="webRadioBtn" checked={this.state.selectedRole === item}
                          onChange={() => this.handleSelectRole(item)}
                          style={{ color: "green" }}
                        />
                      </Box>
                    </Box>
                  </ListItem>
                  <Box margin="auto" width="90%" >
                    <hr style={{ border: "none", borderBottom: "1px solid #CBD5E1", width: "100%", margin: "0", }} />
                  </Box>
                </React.Fragment>
                );
              } else {
                return null;
              }
            })}
          </List>
        </Box>
        <Box mr={2} ml={2}>
          <Button
            fullWidth
            variant="contained"
            data-test-id="roleButton"
            style={{
              height: "45px", color: "white", fontWeight: 700, fontSize: "16px",
              borderRadius: "8px", fontFamily: "MyFont", letterSpacing: "1px",
              backgroundColor: "#14362E", textTransform: "capitalize", marginTop: "20%", marginBottom: "5%"
            }}
            onClick={this.handleRoleSelectButtonMobile}
          >
            Select
          </Button>
        </Box>
      </Drawer>
    )
  };

  industryWebDrawer = () => {
    return (
      <Drawer
        anchor="right"
        open={this.state.industryWebDrawerOpen}
        onClose={this.toggleWebIndustryDrawer(false)}
        PaperProps={{
          style: {
            borderTopLeftRadius: "20px",
            width: "480px",
            padding: "5px",
            boxShadow: "5px 7px 8px 0px !important",
          },
        }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box style={{ display: "flex", padding: "10px" }}>
            <Typography
              style={{
                fontSize: "24px",
                fontWeight: 600,
                color: "#14362E",
                fontFamily: "MyFont",
                letterSpacing: "1.4",
              }}
            >
              Industry
            </Typography>
          </Box>
          <Box>
            <IconButton style={{ right: "10px" }} onClick={this.toggleWebIndustryDrawer(false)} >
              <CloseIcon />
            </IconButton> </Box>
        </Box>

        <Box style={{ display: "flex", padding: "10px" }}>
          <TextField
            fullWidth

            variant="outlined"
            placeholder="Search industry"
            data-test-id="web-search"
            value={this.state.searchIndustryQuery}
            error={this.state.roleError}
            onChange={this.handleIndustrySearch}
            InputProps={{
              style: { borderRadius: "8px", marginTop: "5px" },
              endAdornment: this.state.searchIndustryQuery && (
                <InputAdornment position="end">
                  <IconButton onClick={this.searchIndustryQueryfunc}>
                    <CloseIcon /> </IconButton>
                </InputAdornment>),
              startAdornment: (<InputAdornment position="start">
                <IconButton> <SearchIcon />  </IconButton>
              </InputAdornment>),
            }}
          />
        </Box>
        <Box style={{ overflow: "auto", marginTop: "30px" }}>
          <List>
            {this.state.industry_list.map((item: any, index: number) => {
              const searchIndustryQuery = this.state.searchIndustryQuery.toLowerCase();
              const industryName = item.name.toLowerCase();
              if (!searchIndustryQuery || industryName.includes(searchIndustryQuery)) {
                return (
                  <React.Fragment key={index}>
                    <ListItem
                      button
                      onClick={() => this.handleSelectIndustry(item)}
                      style={{ cursor: "pointer" }}
                    >
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <Box>
                          <ListItemText primary={item.name} />
                        </Box>
                        <Box>
                          <Radio
                            data-test-id="webRadioBtn"
                            checked={this.state.selectedIndustry === item}
                            onChange={() => this.handleSelectIndustry(item)}
                            style={{ color: "green" }}
                          />
                        </Box>
                      </Box>
                    </ListItem>
                    <Box width="90%" margin="auto">
                      <hr style={{ border: "none", borderBottom: "1px solid #CBD5E1", margin: "0", width: "100%" }} />
                    </Box>
                  </React.Fragment>
                );
              } else {
                return null;
              }
            })}
          </List>
        </Box>
        <Box mr={2} ml={2} pl={2} pr={2}>
          <Button
            variant="contained"
            fullWidth
            style={{
              backgroundColor: "#14362E",
              borderRadius: "8px",
              height: "45px",
              color: "white",
              fontWeight: 700,
              fontFamily: "MyFont",
              letterSpacing: "1px",
              fontSize: "16px",
              textTransform: "capitalize",
              marginTop: "20%",
              marginBottom: "5%"
            }}
            onClick={this.handleIndustrySelectButtonWeb}
          >
            Select
          </Button>
        </Box>
      </Drawer>
    )
  };

  industryMobileDrawer = () => {
    return (
      <Drawer
        anchor="right"
        open={this.state.industryMobileDrawerOpen}
        onClose={this.toggleMobileIndustryDrawer(false)}
        PaperProps={{
          style: {
            borderTopRightRadius: "20px",
            marginTop: "18%",
            width: "100%",
          },
        }}
        style={{ marginTop: "20%" }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box style={{ display: "flex", padding: "10px" }}>
            <Typography
              style={{
                fontSize: "24px",
                fontWeight: 600,
                color: "#14362E",
                fontFamily: "MyFont",
                letterSpacing: "1.4",
              }}
            >
              Industry
            </Typography>
          </Box>
          <Box>
            <IconButton
              style={{ right: "10px" }}
              onClick={this.toggleMobileIndustryDrawer(false)} > <CloseIcon />
            </IconButton> </Box>
        </Box>

        <Box
          style={{ display: "flex", padding: "10px" }}>
          <TextField fullWidth variant="outlined"
            placeholder="Search industry" data-test-id="web-search"
            value={this.state.searchIndustryQuery}
            onChange={this.handleIndustrySearch}
            error={this.state.roleError}
            InputProps={{
              style: {
                marginTop: "5px",
                borderRadius: "8px",
              },
              endAdornment: this.state.searchIndustryQuery && (
                <InputAdornment position="end"> <IconButton onClick={this.searchIndustryQueryfunc}>
                  <CloseIcon /> </IconButton> </InputAdornment>),
              startAdornment: (<InputAdornment position="start">
                <IconButton> <SearchIcon />  </IconButton> </InputAdornment>),
            }} />
        </Box>
        <Box style={{ overflow: "auto", marginTop: "30px" }}>
          <List>
            {this.state.industry_list.map((item: any, index: number) => {
              const searchIndustryQuery = this.state.searchIndustryQuery.toLowerCase();
              const industryName = item.name.toLowerCase();
              if (!searchIndustryQuery || industryName.includes(searchIndustryQuery)) {
                return (<React.Fragment key={index}>
                  <ListItem button
                    onClick={() => this.handleSelectIndustry(item)}
                    style={{ cursor: "pointer" }}>
                    <Box style={{
                      width: "100%",
                      display: "flex", alignItems: "center", justifyContent: "space-between",
                    }}
                    >
                      <Box> <ListItemText primary={item.name} /> </Box>
                      <Box>
                        <Radio data-test-id="webRadioBtn"
                          checked={this.state.selectedIndustry === item}
                          onChange={() => this.handleSelectIndustry(item)}
                          style={{ color: "green" }} />
                      </Box> </Box>
                  </ListItem>
                  <Box
                    margin="auto"
                    width="90%"
                  >
                    <hr style={{ width: "100%", margin: "0", border: "none", borderBottom: "1px solid #CBD5E1", }} />
                  </Box>
                </React.Fragment>);
              } else { return null; }
            })}
          </List> </Box>
        <Box ml={2} mr={2} >
          <Button variant="contained" fullWidth
            style={{
              marginTop: "20%", fontSize: "16px",
              height: "45px", color: "white", fontWeight: 700,
              fontFamily: "MyFont", letterSpacing: "1px",
              backgroundColor: "#14362E", borderRadius: "8px",
              textTransform: "capitalize", marginBottom: "5%"
            }}
            onClick={this.handleIndustrySelectButtonMobile}
          >
            Select </Button>
        </Box>
      </Drawer>
    )
  };


  accountDetailsWeb = () => {
    return (
      <Grid container style={{ height: "0px" }} spacing={5}>
        <Grid item md={9} style={{ paddingInline: "80px" }}>
          <Grid container spacing={4}>
            <Grid
              item
              xs={12}
              style={{
                marginTop: "50px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                src={logo}
                alt="kella_logo_txt"

                style={{ width: "249.88px", height: "45.59px" }}
              />

              <Box sx={{ width: "100%", textAlign: "center", marginTop: "25px" }}>
                <img src={groupstepper} alt="" />
              </Box>
              <Typography
                style={{
                  fontSize: "20px",
                  color: "#14362E",
                  fontFamily: "MyFont",
                  letterSpacing: "1.3",
                  fontWeight: "bold",
                  marginTop: "17px"
                }}
              >
                {configJSON.accountDetails}
              </Typography>
              {
                !this.state.roleFieldValid || !this.state.industryFieldValid || !this.state.companyFieldValid ? <Alert style={{ fontSize: "14px", letterSpacing: "0.8px", margin: "20px 0px", fontFamily: "MyFont" }} severity="error">Please fill in the fields below.</Alert> : ""
              }
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={6}>
                  <InputLabel
                    style={webpageStyle.InputLabelStyle}
                  >
                    {configJSON.accountDetailsHeading1}
                  </InputLabel>
                  <TextField
                    fullWidth
                    type="text"
                    data-test-id="txtInputCompany"
                    variant="outlined"
                    placeholder="Company Name"
                    error={!this.state.companyFieldValid}
                    value={this.state.companyTextFieldValue}
                    className="customInputClass"
                    onChange={(e) => {
                      const value = e.target.value;
                      const onlyLetters = value.replace(/[^A-Za-z]/g, '')
                      const isValid = onlyLetters.length > 1;
                      this.setState({
                        companyTextFieldValue: onlyLetters,
                        companyFieldValid: isValid,
                      });
                    }}
                    InputProps={{
                      style: {
                        marginTop: "2%",
                        borderRadius: "8px",
                        width: "100%",
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                  <InputLabel
                    style={webpageStyle.InputLabelStyle}
                  >
                    {configJSON.accountDetailsHeading2}
                  </InputLabel>
                  <TextField
                    fullWidth
                    type="text"
                    variant="outlined"
                    placeholder="Select an option"
                    className="customInputClass"
                    error={!this.state.roleFieldValid}
                    value={this.state.roleTextFieldValue}
                    onClick={this.toggleWebRoleDrawer(true)}
                    InputProps={{
                      style: {
                        marginTop: "2%", borderRadius: "8px",
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={this.toggleWebRoleDrawer(true)}>
                            <ArrowDropDownIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              {this.roleWebDrawer()}
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={6}>
                  <InputLabel
                    style={webpageStyle.InputLabelStyle}
                  >
                    {configJSON.accountDetailsHeading3}

                  </InputLabel>
                  <TextField
                    fullWidth
                    type="text"
                    variant="outlined"
                    placeholder="Select an option"
                    className="customInputClass"
                    error={!this.state.industryFieldValid}
                    value={this.state.industryTextFieldValue}
                    onClick={this.toggleWebIndustryDrawer(true)}
                    InputProps={{
                      style: {
                        marginTop: "2%", borderRadius: "8px",
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={this.toggleWebIndustryDrawer(true)}>
                            <ArrowDropDownIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <InputLabel
                    style={webpageStyle.InputLabelStyle}
                  >
                    {configJSON.accountDetailsHeading4}
                  </InputLabel>
                  <TextField
                    fullWidth
                    type="text"
                    variant="outlined"
                    placeholder="Paste link to profile"
                    value={this.state.linkedInProfile}
                    className="customInputClass"
                    error={this.state.linkedInProfileError}
                    helperText={
                      this.state.linkedInProfileError ? "Please enter a valid URL" : ""
                    }
                    onChange={this.handleChangeLinkedInProfile}
                    InputProps={{
                      style: {
                        marginTop: "2%",
                        borderRadius: "8px",
                        width: "100%",
                      },
                    }}
                  />
                </Grid>
              </Grid>
              {this.industryWebDrawer()}
            </Grid>
            {this.showBackandNextButton()}
          </Grid>
        </Grid>
        <Grid item md={3}>
          <img
            src={women}
            alt="Women"
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              objectFit: "contain",
              position: "fixed",
              right: 0,
            }}
          />
        </Grid>
      </Grid>
    );
  };

  accountDetailsMobile = () => {
    return (
      <Container style={{ display: "flex", justifyContent: "center" }}>
        <Grid item container xs={12} style={{ height: "0px" }}>
          <Grid item xs={12}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: "25px",
              marginTop: "50px",
              alignItems: "center",
            }}>
            <img
              src={logo}
              style={{ height: "45.59px", width: "249.88px" }}
            />
            <Box sx={{ width: "100%", textAlign: "center", marginTop: "10px" }}>
              <img src={groupstepper} alt="" />
            </Box>

            <Typography style={{ fontSize: "20px", fontWeight: 800, color: "#14362E", marginTop: "0px" }}>{configJSON.accountDetails}</Typography>
          </Grid>
          <Grid container spacing={4} justifyContent="center" style={{ width: '100%', margin: '0' }}>
            <Grid item xs={12} md={6}>
              <InputLabel style={{ fontSize: "16px", fontWeight: 700, color: "#334155" }}> {configJSON.accountDetailsHeading1}</InputLabel>
              <TextField
                fullWidth
                type="text"
                data-test-id="txtInputCompany"
                variant="outlined"
                placeholder="Company Name"
                className="customInputClass"
                error={!this.state.companyFieldValid}
                value={this.state.companyTextFieldValue}
                onChange={(e) => {
                  const value = e.target.value;
                  const onlyLetters = value.replace(/[^A-Za-z]/g, '').trim();
                  const isValid = onlyLetters.length > 1;

                  this.setState({
                    companyTextFieldValue: onlyLetters,
                    companyFieldValid: isValid,
                  });
                }}
                InputProps={{
                  style: {
                    marginTop: "2%",
                    borderRadius: "8px",
                    width: "100%",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel style={{ fontSize: "16px", fontWeight: 700, color: "#334155" }}>{configJSON.accountDetailsHeading2}</InputLabel>
              <TextField
                fullWidth
                type="text"
                variant="outlined"
                placeholder="Select an option"
                className="customInputClass"
                error={!this.state.roleFieldValid}
                value={this.state.roleTextFieldValue}
                onClick={this.toggleMobileRoleDrawer(true)}
                InputProps={{
                  style: {
                    marginTop: "2%", borderRadius: "8px",
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={this.toggleMobileRoleDrawer(true)}>
                        <ArrowDropDownIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            {this.roleMobileDrawer()}

            <Grid item xs={12} md={6}>
              <InputLabel style={{ fontSize: "16px", fontWeight: 700, color: "#334155" }}>{configJSON.accountDetailsHeading3}</InputLabel>
              <TextField
                fullWidth
                type="text"
                variant="outlined"
                placeholder="Select an option"
                className="customInputClass"
                error={!this.state.industryFieldValid}
                value={this.state.industryTextFieldValue}
                onClick={this.toggleMobileIndustryDrawer(true)}
                InputProps={{
                  style: {
                    marginTop: "2%", borderRadius: "8px",
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={this.toggleMobileIndustryDrawer(true)}>
                        <ArrowDropDownIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            {this.industryMobileDrawer()}

            <Grid item xs={12} md={6}>
              <InputLabel style={{ fontSize: "16px", fontWeight: 700, color: "#334155", fontFamily: "MyFont" }}>{configJSON.accountDetailsHeading4} <span style={{ fontFamily: "MyFont", fontWeight: "bold", letterSpacing: "0.8px" }}></span></InputLabel>
              <TextField
                fullWidth
                type="text"
                variant="outlined"
                placeholder="Paste link to profile"
                value={this.state.linkedInProfile}
                className="customInputClass"
                error={this.state.linkedInProfileError}
                helperText={
                  this.state.linkedInProfileError ? "Please enter a valid URL" : null
                }
                onChange={this.handleChangeLinkedInProfile}
                InputProps={{
                  style: {
                    marginTop: "2%",
                    borderRadius: "8px",
                    width: "100%",
                  },
                }}
              />
            </Grid>
            {this.showBackandNextButton()}
          </Grid>
        </Grid>
      </Container >
    );
  };

  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={false} style={{ display: "flex" }}>
          <Box
            display={{ xs: "none", sm: "none", md: "flex" }}
            style={{ width: "100%" }}
          >
            {this.accountDetailsWeb()}
          </Box>
        </Container>
        <Box display={{ xs: "flex", sm: "flex", md: "none" }}>
          {this.accountDetailsMobile()}
        </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

export default AccountDetail;

const webpageStyle = {

  InputLabelStyle: {
    fontSize: "16px",
    fontWeight: 700,
    color: "#334155",
    fontFamily: "MyFont",
    letterSpacing: "1.4",
  },
  modalStyle: {
    position: "absolute" as "absolute",
    top: "50%",
    left: { xs: "50%", md: "55%" },
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    width: { xs: "70%", md: "20%" },
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxShadow: 24,
    p: 4,
  }
};
// Customizable Area End
