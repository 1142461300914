import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";

// Customizable Area Start
import {TGetCurrentPlan} from "./types"
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    subscriptionDetailsOpen: boolean
    currentPlanData: TGetCurrentPlan | null
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    getPlanApiCallId: string

    // Customizable Area End
}

export default class PaymentController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getPlanApiCallId = '';
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage),
        ];

        this.state = {
            subscriptionDetailsOpen: false,
            currentPlanData: null
        };
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
        this.handleCloseSubscriptionDetails = this.handleCloseSubscriptionDetails.bind(this)
        this.handleOpenSubscriptionDetails = this.handleOpenSubscriptionDetails.bind(this)

        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("on recieive==>" + JSON.stringify(message));
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
              getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestCallId === this.getPlanApiCallId) {
                this.handleGetPlanResponse(responseJson as TGetCurrentPlan);
            }
          }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        this.getPaymentData();
    }
    getPaymentData () {

    const headers = {
        "Content-Type": 'application/json',
        token: localStorage.getItem("accessToken")
      };
     
      const getPlanMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getPlanApiCallId = getPlanMsg.messageId;
  
      getPlanMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        'bx_block_stripe_integration/plans/current_plan'
      );
  
      getPlanMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      getPlanMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'GET'
      );
      runEngine.sendMessage(getPlanMsg.id, getPlanMsg);
    }
    handleGetPlanResponse(responseJson: TGetCurrentPlan){
        this.setState({currentPlanData: responseJson})
    }
    handleOpenSubscriptionDetails() {
        this.setState({subscriptionDetailsOpen: true});
    }
    handleCloseSubscriptionDetails() {
        this.setState({subscriptionDetailsOpen: false});
    }
    goToBilling() {
        window.location.href = '/Billing'
    }
    goBack() {
        window.history.back();
    }
    handleEdit() {
        window.location.href = '/EditPayment'
    }
    // Customizable Area End
}
