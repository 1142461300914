import React from "react";
// Customizable Area Start
import AllEventController, {
    Props
} from "./AllEventController";
import { AppHeader } from "../../../components/src/AppHeader.web";
import {
    Container,
    Box,
    Card,
    styled,
    Drawer,
    Button,
    CardMedia,
    CardContent,
    Typography,
    Modal,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from "@material-ui/core";
import { CardImg, locationimg, calender, watchimg, moneyImg, groupimg, filterImg, noevents, corssImg, SucessImg } from "../src/assets"
import DatePicker from "react-multi-date-picker"
import "react-multi-date-picker/styles/colors/green.css"

export default class AllEvent extends AllEventController {
    constructor(props: Props) {
        super(props);
    }

    AlleventDisplay() {
        return (
            <>
                {
                    this.state.AllEventsresponse?.length > 0 ? this.state.AllEventsresponse.map((element: any, index: any) => {
                        return (
                            <>
                                <Card style={{ maxWidth: 700, marginBottom: "20px", width: "100%", position: "relative" }}>
                                    <CardMedia
                                        style={{ height: 180 }}
                                        image={element.attributes.image.url}
                                        title="green iguana"
                                    />
                                    <Typography style={{ position: "absolute", right: "20px", top: "10px", background: "#D4E5DE", padding: "1px 11px", color: "#14362E", fontFamily: "MyFont", letterSpacing: "0.8px", borderRadius: "15px" }}>{!element.attributes.is_paid ? "Free" : "Paid"}</Typography>
                                    <CardContent style={{ display: "flex",justifyContent: "space-evenly" }}>
                                        <Box style={{ padding: "11px" }}>
                                            <Typography style={{ fontFamily: "MyFont",letterSpacing:"0.8px" }}>{element.attributes.formatted_date}</Typography>

                                        </Box>
                                        <Box style={{ padding: "10px" }}>
                                            <Typography style={{fontFamily:"MyFont", letterSpacing:"0.8px"}}>{element.attributes.title}</Typography>
                                            <Typography style={{fontSize: "12px", color: "#64748B",  fontFamily: "MyFont", letterSpacing: "0.8px" }}>{element.attributes.notes}</Typography>
                                            <Typography style={{ fontFamily: "MyFont",letterSpacing:"0.8px", fontSize: "12px" }}>{element.attributes.time_duration}</Typography>
                                            <Typography style={{ fontFamily: "MyFont", fontSize: "12px",letterSpacing:"0.8px" }}>{element.attributes.going} Going - {element.attributes.tickets_left} Tickets left</Typography>
                                        </Box>
                                        <Box>
                                            <Typography style={{ fontFamily: "MyFont", cursor: "pointer" ,  marginTop: "40px", width: "80px",fontSize: "15px", fontWeight: "bold"}} onClick={this.handleDetailsOpen.bind(this, element)}>View details</Typography>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </>
                        )
                    }) : (
                        <>
                            <Box>
                                <img src={noevents} alt="" />
                            </Box>
                        </>
                    )
                }


            </>
        )
    }

    MyeventDisplay() {
        return (
            <>
                {
                    this.state.AllEventsresponse.length > 0 ? this.state.AllEventsresponse.map((element: any, index: any) => {
                        return (
                            <>
                                <Card style={{ maxWidth: 700, marginBottom: "20px", width: "100%" }}>
                                    <CardMedia
                                        style={{ height: 180 }}
                                        image={element.attributes.image.url}
                                        title="green iguana"
                                    />
                                    <CardContent style={{ display: "flex", justifyContent: "space-evenly" }}>
                                        <Typography style={{ position: "absolute", right: "55px", top: "78px", background: "#D4E5DE", padding: "1px 11px", color: "#14362E", fontFamily: "MyFont", letterSpacing: "0.8px", borderRadius: "15px" }}>{!element.attributes.is_paid  ? "Free" : "Paid"}</Typography>
                                        <Box style={{ padding: "10px" }}>
                                            <Typography style={{ fontFamily: "MyFont" }}>{element.attributes.formatted_date}</Typography>

                                        </Box>
                                        <Box style={{ padding: "10px" }}>
                                            <Typography>{element.attributes.title}</Typography>
                                            <Typography style={{ color: "#64748B", fontSize: "12px", fontFamily: "MyFont", letterSpacing: "0.8px" }}>{element.attributes.notes}</Typography>
                                            <Typography style={{ fontFamily: "MyFont", fontSize: "12px" }}>{element.attributes.time_duration}</Typography>
                                            <Typography style={{ fontFamily: "MyFont", fontSize: "12px" }}>{element.attributes.going} Going - {element.attributes.tickets_left} Tickets left</Typography>
                                        </Box>
                                        <Box>
                                            <Typography style={{ fontFamily: "MyFont", fontSize: "15px", marginTop: "40px", width: "80px", fontWeight: "bold", cursor: "pointer" }} onClick={this.handleDetailsOpen.bind(this, element)}>View details</Typography>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </>
                        )
                    }) : (
                        <>
                            <Box>
                                <img style={{ marginTop: "50px" }} src={noevents} alt="" />
                            </Box>
                        </>
                    )
                }
            </>
        )
    }

    viewDetailsSidebar() {
        return (
            <>
                {
                    Object.keys(this.state.viewdetailsData).length > 0 && (
                        <>
                            <BoxMain>
                                <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "10px" }}>
                                    <Typography style={{ fontFamily: "MyFont", fontWeight: "bold", letterSpacing: "0.8px", fontSize: "21px" }}>Event Details</Typography>
                                    <span onClick={this.drawerClose} style={{ cursor: "pointer" }}><i className="fa-solid fa-xmark"></i></span>
                                </Box>
                                <Box style={webStyles.borderBottomstyle as any}></Box>
                                <Container>

                                    <Box style={{ display: 'flex', flexDirection: "column", alignItems: "center", padding: "10px" }}>
                                        <Box style={{ padding: "10px",width:"100%" }}>
                                            <img src={this.state.viewdetailsData.attributes?.image.url} style={{ width:"100%", borderRadius: "10px", height: "140px",objectFit:"cover" }} alt="" />
                                        </Box>
                                    </Box>
                                    <Box style={{ display: 'flex', flexDirection: "column", padding: "10px 20px" }}>
                                        <Typography style={{ fontFamily: "MyFont", letterSpacing: "0.8px", fontWeight: "bold" }}>{this.state.viewdetailsData.attributes?.title}</Typography>
                                        <Typography style={{ fontFamily: "MyFont", letterSpacing: "0.8px", fontSize: "12px", color: "#64748B", marginBottom: "10px" }}>{this.state.viewdetailsData.attributes?.notes}</Typography>
                                        <Box>
                                            <Box style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                                                <img src={locationimg} style={{ width: "18px" }} alt="" />
                                                <Typography style={{ fontFamily: "MyFont", fontSize: "12px", fontWeight: "bold", paddingLeft: "4px", letterSpacing: "0.8px" }}>{this.state.viewdetailsData.attributes?.city}</Typography>
                                            </Box>
                                            <Box style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                                                <img src={calender} style={{ width: "18px" }} alt="" />
                                                <Typography style={{ fontFamily: "MyFont", fontSize: "12px", fontWeight: "bold", paddingLeft: "4px", letterSpacing: "0.8px" }}>{this.state.viewdetailsData.attributes?.formatted_date}</Typography>
                                            </Box>
                                            <Box style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                                                <img src={watchimg} style={{ width: "18px" }} alt="" />
                                                <Typography style={{ fontFamily: "MyFont", fontSize: "12px", fontWeight: "bold", paddingLeft: "4px", letterSpacing: "0.8px" }}>{this.state.viewdetailsData.attributes?.time_duration}</Typography>
                                            </Box>
                                            <Box style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                                                <img src={moneyImg} style={{ width: "18px" }} alt="" />
                                                <Typography style={{ fontFamily: "MyFont", fontSize: "12px", fontWeight: "bold", paddingLeft: "4px", letterSpacing: "0.8px" }}>{!this.state.viewdetailsData.attributes?.is_paid ? "free" : `€ ${this.state.viewdetailsData.attributes?.amount}`}</Typography>
                                            </Box>
                                            <Box style={{ display: "flex", alignItems: "center" }}>
                                                <img src={groupimg} style={{ width: "18px" }} alt="" />
                                                <Typography style={{ fontFamily: "MyFont", fontSize: "12px", fontWeight: "bold", paddingLeft: "4px", letterSpacing: "0.8px" }}>{this.state.viewdetailsData.attributes?.going} Going - {this.state.viewdetailsData.attributes?.tickets_left} Tickets left</Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Container>

                                <Box>
                                    <Button variant="contained" style={{ width: "100%", background: "black", color: "white", textTransform: "capitalize", fontFamily: "MyFont" }} onClick={this.handleGoingPurchase.bind(this, this.state.viewdetailsData)}>Going</Button>
                                </Box>
                            </BoxMain>
                        </>
                    )
                }

            </>
        )
    }

    DateAccordian() {
        const formattedRange = this.state.DateValue.length === 2
      ? `${this.formatDate(this.state.DateValue[0])} - ${this.formatDate(this.state.DateValue[1])}`
      : '';
        return (
            <>
                <Accordion style={{ marginBottom: "10px" }}>
                    <AccordionSummary
                        expandIcon={<i className="fa-solid fa-caret-down"></i>}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <Box style={{ display: "flex", flexDirection: "column" }}>
                            <Typography>Date Period </Typography> <br />
                            <Typography>{formattedRange}</Typography>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                    <DatePicker
                                value={this.state.DateValue}
                                range
                                rangeHover
                                onChange={this.getDateRangeValue}
                                className="green"
                            />
                    </AccordionDetails>
                </Accordion>

            </>
        )
    }

    EventTypeAccordian() {
        return (
            <>
                <Accordion style={{ marginBottom: "10px" }}>
                    <AccordionSummary
                        expandIcon={<i className="fa-solid fa-caret-down"></i>}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <Box style={{ display: "flex", flexDirection: "column" }}>
                            <Typography>Event Type </Typography> <br />
                            <Typography>{this.state.eventMode == "in_person" ? "Inperson" : this.state.eventMode}</Typography>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box style={{ width: "100%" }}>
                            <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                                <Typography>virtual</Typography>
                                <input type="radio" value="virtual" onChange={this.handleGetEventMode} name="eventmode" id="" />
                            </Box>
                            <Box style={webStyles.borderBottomstyle as any}></Box>
                            <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                                <Typography>In Person</Typography>
                                <input type="radio" value="in_person" onChange={this.handleGetEventMode} name="eventmode" id="" />
                            </Box>
                            <Box style={webStyles.borderBottomstyle as any}></Box>
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </>
        )
    }

    cityAccordian() {
        return (
            <>
                <Accordion style={{ marginBottom: "10px" }}>
                    <AccordionSummary
                        expandIcon={<i className="fa-solid fa-caret-down"></i>}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <Box style={{ display: "flex", flexDirection: "column" }}>
                            <Typography>City </Typography> <br />
                            <Box style={{ display: "flex", flexWrap: "wrap" }}>
                                {
                                    this.state.selectedcity && this.state.selectedcity.map((element: any, index: any) => {
                                        return (
                                            <>
                                                <Typography style={{fontFamily:"MyFont",letterSpacing:"0.8px"}}>{`${element.value},`}</Typography>
                                            </>
                                        )
                                    })
                                }

                            </Box>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box style={{ width:"100%" }}>
                            <Box style={{ display: 'flex', justifyContent: "center", alignItems: "center", flexDirection: "column", padding: "14px" }}>
                                <Box style={{ width: "100%",  position: "relative",marginBottom: "10px", border: "1px solid #CBD5E1", marginTop: "20px", display: "flex", alignItems: "center", borderRadius: "5px", padding: "0 5px", fontFamily: "MyFont" }}>
                                    <i className="fa-solid fa-magnifying-glass" style={{ color:"#94A3B8" }}></i>
                                    <input type="text" value={this.state.setSearchvalue} style={{ width: "100%", padding: "10px 5px", borderRadius: "5px", border: "none", outline: "none", fontFamily: "MyFont" }} onChange={this.handlesearchCity} name="" id="" />
                                    {
                                        this.state.setSearchvalue ? <i className="fa-solid fa-xmark" style={{ cursor: "pointer" }} data-test-id="removesearch" onClick={this.removesearchcitydata}></i> : <i className="fa-solid fa-xmark" style={{ color: "#94A3B8" }}></i>
                                    }
                                </Box>
                            </Box>
                            <Box style={{ display: "flex", alignItems: "center", justifyContent: "center", flexWrap: "wrap" }}>
                                {
                                    this.state.selectedcity && this.state.selectedcity.map((element: any, index: any) => (
                                        <Box key={1} style={{ borderRadius: "20px", display: "flex", alignItems: "center", width: "auto", background: "#F1F5F9", flexWrap: "wrap", marginBottom: "15px" }}>
                                            <Typography style={{ fontSize: "10px", marginRight: "8px", color: "#475569", padding: "5px" }}>{element.value}</Typography>
                                            <span onClick={this.handleselectmemberDelete.bind(this, index)} style={{ cursor: "pointer" }}><i className="fa-solid fa-xmark" style={{ fontSize: "10px" }}></i></span>
                                        </Box>
                                    ))
                                }
                            </Box>
                            {
                                this.state.cityDataList && this.state.cityDataList.map((element: any, index: any) => {
                                    const checkdata = this.state.selectedcity.map((ele: any) => ele.id == element.id)
                                    return (
                                        <>
                                            <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                                                <Typography>{element.name}</Typography>
                                                <input type="checkbox" checked={checkdata.some((eleme: any) => eleme)} onClick={(elementvalue) => this.getvaluemembercheck(elementvalue, element)} name="" id="" />
                                            </Box>
                                        </>
                                    )
                                })
                            }

                            <Box style={webStyles.borderBottomstyle as any}></Box>

                        </Box>
                    </AccordionDetails>
                </Accordion>
            </>
        )
    }

    categoryAccordian() {
        return (
            <>
                <Accordion style={{ marginBottom: "10px" }}>
                    <AccordionSummary
                        expandIcon={<i className="fa-solid fa-caret-down"></i>}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <Box style={{ display: "flex", flexDirection: "column" }}>
                            <Typography>Category </Typography> <br />
                            <Box style={{ display: "flex", flexWrap: "wrap" }}>
                                {
                                    this.state.selectedcategory && this.state.selectedcategory.map((element: any, index: any) => {
                                        return (
                                            <>
                                                <Typography>{`${element.value},`}</Typography>
                                            </>
                                        )
                                    })
                                }
                            </Box>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box style={{ width: "100%" }}>
                            <Box style={{ display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "center", padding: "14px" }}>
                                <Box style={{ width: "100%", marginBottom: "10px", border: "1px solid #CBD5E1", position: "relative", marginTop: "20px", display: "flex", alignItems: "center", borderRadius: "5px", padding: "0 5px", fontFamily: "MyFont" }}>
                                    <i className="fa-solid fa-magnifying-glass" style={{ color: "#94A3B8" }}></i>
                                    <input type="text" style={{ width: "100%", padding: "10px 5px", borderRadius: "5px", border: "none", outline: "none", fontFamily: "MyFont" }} value={this.state.setSearchCategoryvalue} onChange={this.handlesearchCategoryData} name="" id="" />
                                    {
                                        this.state.setSearchCategoryvalue ? <i className="fa-solid fa-xmark" style={{ cursor: "pointer" }} data-test-id="removesearch" onClick={this.removesearchcategorydata}></i> : <i className="fa-solid fa-xmark" style={{ color: "#94A3B8" }}></i>
                                    }
                                </Box>
                            </Box>
                            <Box style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                {
                                    this.state.selectedcategory && this.state.selectedcategory.map((element: any, index: any) => (
                                        <Box key={1} style={{ borderRadius: "20px", display: "flex", alignItems: "center", width: "auto", background: "#F1F5F9", flexWrap: "wrap", marginBottom: "15px" }}>
                                            <Typography style={{ fontSize: "10px", marginRight: "8px", color: "#475569", padding: "5px" }}>{element.value}</Typography>
                                            <span onClick={this.handleselectcategoryDelete.bind(this, index)} style={{ cursor: "pointer" }}><i className="fa-solid fa-xmark" style={{ fontSize: "10px" }}></i></span>
                                        </Box>
                                    ))
                                }
                            </Box>
                            {
                                this.state.categoryDataList && this.state.categoryDataList.map((element: any, index: any) => {
                                    const checkdata = this.state.selectedcategory.map((ele: any) => ele.id == element.id)
                                    return (
                                        <>
                                            <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                                                <Typography>{element.attributes.name}</Typography>
                                                <input type="checkbox" name="" checked={checkdata.some((eleme: any) => eleme)} onClick={(elementvalue) => this.getvaluecategorycheck(elementvalue, element)} id="" />
                                            </Box>
                                        </>
                                    )
                                })
                            }
                            <Box style={webStyles.borderBottomstyle as any}></Box>
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </>
        )
    }

    EventPriceAccordian() {
        return (
            <>
                <Accordion style={{ marginBottom: "10px" }}>
                    <AccordionSummary
                        expandIcon={<i className="fa-solid fa-caret-down"></i>}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <Box style={{ display: "flex", flexDirection: "column" }}>
                            <Typography>Event Price</Typography> <br />
                            <Typography>{this.state.eventPrice}</Typography>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box style={{ width: "100%" }}>
                            <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                                <Typography>All</Typography>
                                <input type="radio" name="price" value="all" onChange={this.handleGetEventPrice} id="" />
                            </Box>
                            <Box style={webStyles.borderBottomstyle as any}></Box>
                            <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                                <Typography>Paid</Typography>
                                <input type="radio" name="price" value="Paid" onChange={this.handleGetEventPrice} id="" />
                            </Box>
                            <Box style={webStyles.borderBottomstyle as any}></Box>

                            <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                                <Typography>Free</Typography>
                                <input type="radio" name="price" value="Free" onChange={this.handleGetEventPrice} id="" />
                            </Box>
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </>
        )
    }

    filterDrawer() {
        return (
            <>
                <BoxMain>
                    <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "10px" }}>
                        <Typography style={{ fontFamily: "MyFont", fontWeight: "bold", letterSpacing: "0.8px", fontSize: "21px" }}>Filter</Typography>
                        <span onClick={this.drawerClosefilter} style={{ cursor: "pointer" }}><i className="fa-solid fa-xmark"></i></span>
                    </Box>
                    <Box style={webStyles.borderBottomstyle as any}></Box>

                    <Container>
                        <Box style={{ padding: "10px" }}>

                            {this.DateAccordian()}

                            {this.EventTypeAccordian()}

                            {this.cityAccordian()}

                            {this.categoryAccordian()}

                            {this.EventPriceAccordian()}
                        </Box>

                    </Container>

                    <Box>
                        <Button variant="contained" style={{ width: "100%", background: "black", color: "white", textTransform: "capitalize", fontFamily: "MyFont" }} onClick={this.handleApplyfilter}>Apply Filter</Button>
                    </Box>
                </BoxMain>
            </>
        )
    }

    ErrorModel() {
        return (
            <>
                <Modal
                    open={this.state.errorTicket}
                    onClose={this.handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, background: '#fff', padding: 4, }}>
                        <Box style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <Typography style={{ marginTop: "10px" }}><img src={corssImg} alt="" /></Typography>
                            <Typography style={{ marginTop: "10px", fontFamily: "MyFont", letterSpacing: "0.8px", fontWeight: "bold", fontSize: "18px" }}>Ticket Unavailable</Typography>
                            <Typography style={{ marginTop: "10px", textAlign: "center", fontFamily: "MyFont", letterSpacing: "0.8px", fontSize: "13px" }}>Ticket unavailable Please try again <br /> later.</Typography>
                            <Box style={{ width: "100%", marginTop: "10px" }}>
                                <Button variant="contained" style={{ width: "100%", background: "black", color: "white", textTransform: "capitalize", fontFamily: "MyFont" }} onClick={this.handleClose}>Understand</Button>
                            </Box>
                        </Box>

                    </Box>
                </Modal>
            </>
        )
    }

    EventSucessModel() {
        return (
            <>
                <Modal
                    open={this.state.TicketSucess}
                    onClose={this.handleCloseSucess}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, background: '#fff', padding: 4, }}>
                        <Box style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <Typography style={{ marginTop: "10px" }}><img src={SucessImg} alt="" /></Typography>
                            <Typography style={{ marginTop: "10px", fontFamily: "MyFont", letterSpacing: "0.8px", fontWeight: "bold", fontSize: "18px" }}>You’re set for your next event!</Typography>
                            <Typography style={{ marginTop: "10px", textAlign: "center", fontFamily: "MyFont", letterSpacing: "0.8px", fontSize: "13px" }}>Your ticket will be available in the My Events section.</Typography>
                            <Box style={{ width: "100%", marginTop: "10px" }}>
                                <Button variant="contained" style={{ width: "100%", background: "black", color: "white", textTransform: "capitalize", fontFamily: "MyFont" }} onClick={this.handleCloseSucess}>Understand</Button>
                            </Box>
                        </Box>

                    </Box>
                </Modal>
            </>
        )
    }

    render() {
        return (
            <>
                <AppHeader />

                <Box key={1} style={{ display: "flex", flexDirection: "column", alignItems: "center", background: "#F8FAFC", padding: "10px" }}>
                    <Card style={{ maxWidth: 750, marginBottom: "10px", minHeight: "100vh", width: "100%", marginTop: "25px", fontFamily: "MyFont", borderRadius: "13px", padding: "10px", position: "relative" }}>
                        <Box style={webStyles.tabStyle as any}>
                            <Typography style={this.state.Tabview == "allevents" ? webStyles.posttab as any : { width: "50%", color: "#64748B", borderRadius: 25, textAlign: "center", cursor: "pointer", fontFamily: "MyFont" }} onClick={this.handleTabChange.bind(this, "allevents")}>All Events</Typography>
                            <Typography style={this.state.Tabview == "myevents" ? webStyles.posttab as any : { background: "#F8FAFC", color: "#64748B", marginLeft: "10px", fontWeight: "bold", fontFamily: "MyFont", cursor: "pointer" }} onClick={this.handleTabChange.bind(this, "myevents")}>My Events</Typography>
                        </Box>
                        <Box style={{ position: "absolute", top: "22px", right: "33px",cursor:"pointer" }}>
                            <img src={filterImg} alt="" onClick={this.handleFilterDrawerOpen} />
                        </Box>
                        <Container>
                            <Box style={{ display: "flex", flexDirection: "column", alignItems: "center", background: "#F8FAFC", marginTop: "10px" }}>
                                {
                                    this.state.Tabview == "allevents" ? (
                                        <>
                                            {this.AlleventDisplay()}
                                        </>
                                    ) : (
                                        <>
                                            {this.MyeventDisplay()}
                                        </>
                                    )
                                }
                            </Box>
                        </Container>
                    </Card>
                </Box>
                <Drawer style={webStyles.drawer as any} anchor="right" open={this.state.open} onClose={this.toggleDrawer.bind(this, false)}>
                    {this.viewDetailsSidebar()}
                </Drawer>

                <Drawer style={webStyles.drawer as any} anchor="right" open={this.state.openFilter} onClose={this.toggleDrawerfilter}>
                    {this.filterDrawer()}
                </Drawer>
                {this.ErrorModel()}
                {this.EventSucessModel()}
            </>
        )
    }
}

const BoxMain = styled(Box)({

    width: "420px",
    "@media(max-width: 600px)": {
        width: '100vw',
    },
})

const webStyles = {
    tabStyle: {
        display: "flex",
        backgroundColor: "#F8FAFC",
        margin: "auto",
        marginTop: "0px",
        padding: "10px 8px",
        fontFamily: "MyFont",
        width: "300px",
    },
    posttab: {
        border: "2px solid #73A58E",
        width: "50%",
        fontWeight: "bold",
        textAlign: "center",
        cursor: "pointer",
        fontFamily: "MyFont",
        borderRadius: 25,
    },
    drawer: {
        width: "501px",
        display: "block",
        overflowY: "unset",
        '@media (min-width: 600px)': {
            display: "none",
        },
        "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: "240px",
        },
    },
    borderBottomstyle: {
        marginTop: "10px",
        border: "1px solid #E2E8F0",
    }

}
// Customizable Area End