import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { TPayment, TPaymentResponse } from "./types";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    payments: TPaymentResponse | null
    paymentsBySelectedDate: TPayment[]
    selectedDate: string
    subscriptionDetailsOpen: boolean;
    dates: string[]
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    getPaymentHistoryApiCallId: string
    // Customizable Area End
}

export default class BillingController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getPaymentHistoryApiCallId = ''
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage),
        ];

        this.state = {
            subscriptionDetailsOpen: false,
            payments: null,
            paymentsBySelectedDate: [],
            selectedDate: '',
            dates: []
        };
        this.getPaymentHistoryData = this.getPaymentHistoryData.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("on recieive==>" + JSON.stringify(message));

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
              getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestCallId === this.getPaymentHistoryApiCallId) {
                this.handleGetPaymentHistory(responseJson as TPaymentResponse)
            }
          }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        this.getPaymentHistoryData();
      }
    handleGoBack() {
        window.history.back();
    }
    getPaymentHistoryData () {

        const headers = {
            "Content-Type": 'application/json',
            token: localStorage.getItem("accessToken")
          };
          
          const getPaymentMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
          this.getPaymentHistoryApiCallId = getPaymentMsg.messageId;
      
          getPaymentMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            'bx_block_stripe_integration/plans/payment_history'
          );
      
          getPaymentMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
          );
          getPaymentMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            'GET'
          );
          runEngine.sendMessage(getPaymentMsg.id, getPaymentMsg);
    }
    handleGetPaymentHistory (data: TPaymentResponse) {
        const paymentsByDates = Object.keys(data);
        if(paymentsByDates.length > 0) {
            this.setState({ selectedDate: paymentsByDates[0], paymentsBySelectedDate: data[paymentsByDates[0]], dates: paymentsByDates})
        }
        this.setState({payments: data});
    }
    handleChangeDate = (event: React.ChangeEvent<{value: unknown}>) => {
        this.setState({selectedDate: event.target.value as string})
    }
    // Customizable Area End
}
