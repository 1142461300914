import React from "react";
import { Box, Chip, Typography, styled } from "@material-ui/core"
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

type CardOptionT = {
    title: string;
    description?: string;
  } 
type TFeature = CardOptionT & {id: number};

 const SubscriptionCard =({name, features, handleViewDetails, amount, status, title, interval, selectable, isSelected, onSelect }: any) => {
    const cardClasses = ['subscriptionOverride'];
    if(selectable && isSelected){
      cardClasses.push('selected')
    }
    if(status === 'Active') {
      cardClasses.push('active')
    }
    return (
        <StyledWrapper className={cardClasses.join(' ')} onClick={selectable && onSelect}>
        <Box style={{display:'flex', gap:'8px', flexDirection:'column'}}>
          <Box style={{display:'flex', justifyContent:'space-between'}}>
            {name && <Typography style={{fontWeight: 700, fontSize:'16px' }}>{name}</Typography>}
            {status && <Chip  size='small' style={{backgroundColor: "#14362E", color:' white', fontWeight: 'bold', justifySelf:'flex-end'}} label={status} />}
          </Box>
          {title && <Typography style={{
            fontFamily: 'MyFont',
            fontSize: '11px',
            fontWeight: 400,
            lineHeight: '16px',
            letterSpacing: '0.08em',
          }}>{title}</Typography>}
          {features?.map(({title,description, id}: TFeature) => <CardOtpion key={id} title={title} description={description}/>)}
        </Box>
        <Box style={{display: 'flex', justifyContent: 'space-between', marginTop: '16px'}}>
            <Box style={{ width:"212px", height:"28px",display:"flex"}}>
              <CustomTypography style={{lineHeight:"28px", fontFamily:"Montserrat",fontWeight:700,fontSize:"20px",}}>&#x20AC; {amount}</CustomTypography>
              <Typography style={{lineHeight:"16px", fontWeight:400,fontSize:"11px",fontFamily:"MyFont",textAlign:"center",color:"#94A3B8",paddingTop:"5px"}}>+VAT </Typography>
              <Typography style={{lineHeight:"22px", fontWeight:500,fontSize:"14px",fontFamily:"MyFont",textAlign:"center",color:"#94A3B8",paddingTop:"1px"}}> / {interval}</Typography>
            </Box>
            {handleViewDetails && <Typography style={{
              color: "#14362E",
              letterSpacing: "0.08em",
              fontFamily: "'MyFont', 'Halyard Display'",
              lineHeight:"22px",
              fontSize:"14px",
              fontWeight:700,
              textAlign:"center",
              cursor:"pointer",
              }}
              onClick={handleViewDetails}
              >View details</Typography>}
            
        </Box>
    </StyledWrapper>
    )
}

const CardOtpion = ({title, description}:CardOptionT) => {
    return (
        <Box style={{display:'flex', gap: '12px'}}>
        <CheckCircleIcon fontSize='small' style={{fill:"#73A58E", width: '16px', height:'16px'}}/>
        <Box>
            <Typography style={webStyle.cardItemTitle}>{title}</Typography>
            {description && <Typography style={webStyle.cardItemDescription}>{description}</Typography>}
        </Box>
        </Box>
    )
    
}

const CustomTypography = styled(Typography)({
    '& .MuiTypography-body1': {
      fontFamily: 'MyFont',
    },
  
  });
const StyledWrapper = styled(Box)({
    maxWidth: "450px",
    width:'100%',
    borderRadius: "8px 8px 32px 8px",
    padding:"16px",
    boxSizing: 'border-box' as const,
    '&.active': {
        border: '2px solid #73A58E'
    },
    "&.selected": {
      border: '1px solid black'
    }
})

const webStyle = {
 
    cardItemTitle: {
      fontSize:"12px",
      fontFamily: "MyFont",
      fontWeight:700,
      letterSpacing:"8%",
      lineHeight:"18px",
    },
    cardItemDescription: {
      fontSize:"12px",
      fontFamily: "MyFont",
      fontWeight:500,
      letterSpacing:"8%",
      lineHeight:"18px",
      color: '#64748B'
    },
  };

  export default SubscriptionCard;