export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const logo = require("../assets/logo.jpeg");
export const women = require("../assets/women.png");
export const personalprofile = require("../assets/personalprofile.png");
export const interests = require("../assets/interests.png");
export const worries = require("../assets/worries.png");
export const passion = require("../assets/passion.png");
export const expertise = require("../assets/expertise.png");
export const hobbies = require("../assets/hobbies.png");
export const newlogo = require("../assets/newlogo.png")
export const check_circle = require("../assets/check_circle.png")
export const history_toggle_off = require("../assets/history_toggle_off.png")
export const hourglass = require("../assets/hourglass.png")
export const defaultAvatar = require("../assets/Avatar_.png")
export const greeTickImg = require("../assets/greenTick.png")
export const avatar = require("../assets/avatar.jpeg")
export const LinkImg = require("../assets/linkimg.jpeg")
export const reviewsImg = require('../assets/image_reviews.png');
export const bookmarkImg = require('../assets/bookmark.png');
export const professionalDetailsImg = require('../assets/professional_details.png')
export const groupstepper = require('../assets/group_steper_mobile.jpeg')
export const other_profile = require('../assets/other_profile.png')